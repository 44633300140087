import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { PiPaperPlaneTiltLight } from 'react-icons/pi'; // Example icon
import { RiErrorWarningLine } from 'react-icons/ri';
import api from '../utils/api'; // Adjust the path as needed

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required.';
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Valid email is required.';
    }
    if (!formData.message.trim()) newErrors.message = 'Message cannot be empty.';
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setLoading(true);
    try {
      await api.post('/contact', formData);
      setResponseMessage('Thank you! Your message has been sent.');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || 'Something went wrong. Please try again.';
      setResponseMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 500,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="h5" textAlign="center">
        Contact Us
      </Typography>

      <TextField
        name="name"
        label="Name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        error={!!errors.name}
        helperText={errors.name}
        slotProps={{
          input: {
            startAdornment: <RiErrorWarningLine style={{ color: errors.name ? 'red' : 'inherit' }} />,
          },
        }}
      />

      <TextField
        name="email"
        label="Email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        required
        slotProps={{
          input: {
            startAdornment: <RiErrorWarningLine style={{ color: errors.email ? 'red' : 'inherit' }} />,
          },
        }}
       
      />

      <TextField
        name="message"
        label="Message"
        value={formData.message}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        required
        error={!!errors.message}
        helperText={errors.message}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} /> : <PiPaperPlaneTiltLight />}
      >
        {loading ? 'Sending...' : 'SEND'}
      </Button>

      {responseMessage && (
        <Typography
          variant="body2"
          color={responseMessage.startsWith('Thank you') ? 'success.main' : 'error.main'}
          textAlign="center"
          aria-live="polite"
        >
          {responseMessage}
        </Typography>
      )}
    </Box>
  );
};

export default ContactForm;
