// MemoryEditor.js
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  RiAddLine,
  RiDeleteBinLine,
  RiFileCopyLine,
  RiRefreshLine,
  RiCloseLine,
  RiSaveLine,
} from 'react-icons/ri';

const RenderField = ({ label, value, onChange, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle opening the add field menu
  const handleAddFieldClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the add field menu
  const handleAddFieldClose = () => {
    setAnchorEl(null);
  };

  // Handle adding a new field
  const handleAddField = (type) => {
    handleAddFieldClose();
    let newKey = '';

    if (Array.isArray(value)) {
      newKey = value.length;
    } else {
      newKey = prompt('Enter the key for the new field:');
      if (!newKey) return;
    }

    let newValue;
    switch (type) {
      case 'object':
        newValue = {};
        break;
      case 'array':
        newValue = [];
        break;
      case 'boolean':
        newValue = false;
        break;
      case 'number':
        newValue = 0;
        break;
      default:
        newValue = '';
    }

    if (Array.isArray(value)) {
      onChange([...value, newValue]);
    } else {
      onChange({ ...value, [newKey]: newValue });
    }
  };

  // Rendering for objects
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    return (
      <div style={{ marginLeft: '1em', position: 'relative' }}>
        <Typography variant="subtitle1">
          {label}
          <IconButton size="small" onClick={handleAddFieldClick}>
            <RiAddLine />
          </IconButton>
          {onDelete && (
            <IconButton size="small" onClick={onDelete}>
              <RiDeleteBinLine />
            </IconButton>
          )}
        </Typography>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAddFieldClose}
        >
          <MenuItem onClick={() => handleAddField('string')}>Add String</MenuItem>
          <MenuItem onClick={() => handleAddField('number')}>Add Number</MenuItem>
          <MenuItem onClick={() => handleAddField('boolean')}>Add Boolean</MenuItem>
          <MenuItem onClick={() => handleAddField('object')}>Add Object</MenuItem>
          <MenuItem onClick={() => handleAddField('array')}>Add Array</MenuItem>
        </Menu>

        {Object.entries(value).map(([key, val]) => (
          <RenderField
            key={key}
            label={key}
            value={val}
            onChange={(newVal) => onChange({ ...value, [key]: newVal })}
            onDelete={() => {
              const updatedValue = { ...value };
              delete updatedValue[key];
              onChange(updatedValue);
            }}
          />
        ))}
      </div>
    );
  }
  // Rendering for arrays
  else if (Array.isArray(value)) {
    return (
      <div style={{ marginLeft: '1em', position: 'relative' }}>
        <Typography variant="subtitle1">
          {label}
          <IconButton size="small" onClick={handleAddFieldClick}>
            <RiAddLine />
          </IconButton>
          {onDelete && (
            <IconButton size="small" onClick={onDelete}>
              <RiDeleteBinLine />
            </IconButton>
          )}
        </Typography>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAddFieldClose}
        >
          <MenuItem onClick={() => handleAddField('string')}>Add String</MenuItem>
          <MenuItem onClick={() => handleAddField('number')}>Add Number</MenuItem>
          <MenuItem onClick={() => handleAddField('boolean')}>Add Boolean</MenuItem>
          <MenuItem onClick={() => handleAddField('object')}>Add Object</MenuItem>
          <MenuItem onClick={() => handleAddField('array')}>Add Array</MenuItem>
        </Menu>

        {value.map((item, index) => (
          <RenderField
            key={index}
            label={`Item ${index}`}
            value={item}
            onChange={(newVal) => {
              const newArray = [...value];
              newArray[index] = newVal;
              onChange(newArray);
            }}
            onDelete={() => {
              const newArray = [...value];
              newArray.splice(index, 1);
              onChange(newArray);
            }}
          />
        ))}
      </div>
    );
  }
  // Rendering for booleans
  else if (typeof value === 'boolean') {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          }
          label={label}
        />
        {onDelete && (
          <IconButton size="small" onClick={onDelete}>
            <RiDeleteBinLine />
          </IconButton>
        )}
      </div>
    );
  }
  // Rendering for strings and numbers
  // Rendering for strings and numbers
else {
  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <TextField
        label={label}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={Math.min((value || '').split('\n').length, 4)}
        sx={{
          '& .MuiInputBase-root textarea': {
            resize: 'vertical',
            overflow: 'auto',
          },
        }}
      />
      <Tooltip title="Delete">
        <IconButton
          size="small"
          onClick={() => {
            if (window.confirm('Delete this string? This will clear its content.')) {
              onChange({}); // Replace string with an empty object
            }
          }}
        >
          <RiDeleteBinLine />
        </IconButton>
      </Tooltip>
    </div>
  );
}
};
const MemoryEditor = ({
  memory,
  open,
  onClose,
  onSave,
  onDelete,
  onClone,
  onRefresh,
}) => {
  const [content, setContent] = useState(memory.Details || memory.value);

  // Handle saving the memory
  const handleSave = () => {
    onSave({ ...memory, value: content });
    onClose();
  };

  // Handle deleting the memory
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this memory?')) {
      onDelete(memory);
      onClose();
    }
  };

  // Handle cloning the memory
  const handleClone = () => {
    const newKey = prompt('Enter the key for the cloned memory:', `${memory.Memory || memory.key}_copy`);
    if (newKey) {
      onClone({ ...memory, key: newKey });
    }
  };

  // Handle refreshing the memory
  const handleRefresh = () => {
    onRefresh(memory);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Edit Memory: {memory.Memory || memory.key}
        <div style={{ float: 'right' }}>
          <Tooltip title="Refresh">
            <IconButton size="small" onClick={handleRefresh}>
              <RiRefreshLine />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clone">
            <IconButton size="small" onClick={handleClone}>
              <RiFileCopyLine />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton size="small" onClick={handleDelete}>
              <RiDeleteBinLine />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton size="small" onClick={onClose}>
              <RiCloseLine />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>

      <DialogContent dividers>
        <RenderField label="Content" value={content} onChange={setContent} />
      </DialogContent>

      <DialogActions>
        <Tooltip title="Cancel">
          <IconButton onClick={onClose} color="secondary">
            <RiCloseLine />
          </IconButton>
        </Tooltip>
        <Tooltip title="Save">
          <IconButton onClick={handleSave} color="primary">
            <RiSaveLine />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default MemoryEditor;
