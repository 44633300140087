// constants/StepTypes.js

const { z } = require('zod');

const stepTypeKeys = ['AI', 'AppData', 'FlowControl', 'Memory', 'UserInput']; 

const StepTypes = {
  AI: {
    OPERATIONS: {
      generate: { /* chat completion */
        inputSchema: z.object({
          prompt: z.any().optional(),  /* user input to the step, if needed */
          context: z.any().optional(), /* used to pass variables from other steps: context: {"data1":"${variableName1}", "data2":"${variableName2}"..} */
          systemInstructions: z.any().optional(), /* important instructions that the logic flow depends on, this is usually defined as value and not expected to be dynamically set during flow run */
          outputFormat: z.enum(['text', 'json_object']).optional(),  /* for intermediate steps, always use json_object, while for output to user, use text */
          model: z.string().optional().default('default'), /* enum: default | large */
          temperature: z.number().optional().default(0.1), /* float between 0 and 1 */
        }),
      },
      extract: { /* similar to chat completion but with hard coded instructions to transform input into the format of the output variable, better use generate for most cases */
        inputSchema: z.object({
          prompt: z.string().optional(),
          context: z.any().optional(),
          systemInstructions: z.any().optional(), // not really needed here since extract has hard coded instructions
          model: z.string().optional().default('default'), 
          temperature: z.number().optional().default(0.1), 
        }),
      },
      iterate: { /* split the data and run "generate" on each chunk and return the combined results */
        inputSchema: z.object({
          data: z.any(), /* data to be iterated over */
          chunkSize: z.number().optional(),
          splitStrategy: z.string().optional(), /* enum: 'byProperty' | 'default' -- default will split based on the type of data.. e.g. array will be split into chunkSize items, strings into chunks of chunkSize chars. better use arrays. and for objects use byProperty */
          prompt: z.any().optional(),
          context: z.any().optional(),
          includeIterationContext: z.boolean().optional().default(true), /* leave default */
          includePreviousResults: z.boolean().optional().default(false),  /* include only if you need to pass the results of the previous iterations to the next iteration */
          systemInstructions: z.any().optional(),
          outputFormat: z.enum(['text', 'json_object']).optional(),
          model: z.string().optional().default('default'), 
          temperature: z.number().optional().default(0.1), 
        }),
      },
    },
  },
  AppData: {
    OPERATIONS: {
      create: { /* create a new record of "spaces" or "flows" */
        inputSchema: z.object({
          entity: z.string(), /* enum: 'spaces' | 'flows' */
          data: z.object({}).passthrough().optional(), /* data to be created {name: "space name", description: "space description"} */
        }),
      },
      readOne: {
        inputSchema: z.object({
          entity: z.string(),
          id: z.union([z.string(), z.number()]),
          columns: z.union([z.array(z.string()), z.string()]).optional(),
        }),
      },
      readOwned: {
        inputSchema: z.object({
          entity: z.string(),
          columns: z.union([z.array(z.string()), z.string()]).optional(),
          conditions: z.object({}).optional(),
        }),
      },
      readInSpace: {
        inputSchema: z.object({
          entity: z.string(),
          spaceId: z.union([z.string(), z.number()]),
          columns: z.union([z.array(z.string()), z.string()]).optional(),
          conditions: z.object({}).optional(),
        }),
      },
      update: {
        inputSchema: z.object({
          entity: z.string(),
          id: z.union([z.object({}).passthrough(), z.array(z.any()),z.string()]),
          data: z.object({}).passthrough(),
        }),
      },
      delete: {
        inputSchema: z.object({
          entity: z.string(),
          id: z.union([z.object({}).passthrough(), z.array(z.any()), z.string()]),
        }),
      },
      updateMany: {
        inputSchema: z.object({
          entity: z.string(),
          criteria: z.object({}).passthrough(),
          data: z.object({}).passthrough(),
        }),
      },
      validateFlow: { 
        inputSchema: z.object({
          flowId: z.string().uuid(),
        }), /* returns a boolean value if the flow has a valid configuration */
      },
    },
  },
  FlowControl: {
    OPERATIONS: {
      runSubFlow: {
        inputSchema: z.object({
          subFlowId: z.string(),
        }),
      },
      endFlow: {
        inputSchema: z.object({
          message: z.string(),
        }), /* only needed for conditional steps */
      },
    },
  },
  Memory: {
    OPERATIONS: {
      read: {
        inputSchema: z.object({
          keys: z.union([z.string(), z.array(z.string())]),
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
        }),
      },
      retrieve: {
        inputSchema: z.object({
          query: z.string(),
          topN: z.number().int().min(1).optional().default(5),
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
        }),
      },
      write: {
        inputSchema: z.object({
          memoryEntries: z.array(
            z.object({
              key: z.string(),
              value: z.any(),
              metadata: z.object({}).optional(),
            })
          ),
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
        }),
      },  
      writeOne: {
        inputSchema: z.object({
          key: z.string(),
          value: z.any(),
          metadata: z.object({}).optional(),
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
        }),
      },  
      forget: {
        inputSchema: z.object({
          keys: z.union([z.string(), z.array(z.string())]),
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
        }),
      },
      list: {
        inputSchema: z.object({
          scope: z.enum(['system', 'space', 'user', 'flow']).default('user'),
          fields: z.union([z.string(), z.array(z.string())]).optional().default('key'),
          limit: z.number().int().min(1).optional().default(100),
          skip: z.number().int().min(0).optional().default(0),
        }),
      },
    },
  },
  UserInput: {
    OPERATIONS: {
      collect: {
        inputSchema: z.object({
          inputPrompt: z.string().optional(),
        }),
      },
      select: {
        inputSchema: z.object({
          options: z.union([
            z.array(z.object({}).passthrough()), 
            z.object({}).passthrough(),
            z.array(z.string()) 
          ]),
          inputPrompt: z.string().optional(),
        }),
      },
    },
  },
};

module.exports = StepTypes;
