import React, { useState, useEffect } from 'react';
import { fetchFlowRun } from '../../../../../services/flowService';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../../redux/selectors';
import { useAuth0 } from '@auth0/auth0-react'; 
import AFJSONViewer from '../../../../../components/AFJSONViewer';

const FlowRunDisplay = ({ visible }) => {
  const [flowRunData, setFlowRunData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const flowRun = useSelector(selectFlowRun);
  const flowRunStatus = flowRun?.status || 'undefined';

  const { getAccessTokenSilently } = useAuth0();

  // Function to handle data fetching
  const handleRefresh = async () => {
    if (loading) return; // Prevent multiple simultaneous fetches
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.aflow.ai/api',
        scope: 'readApi',
      });

      if (!flowRun.flowRunId) {
        setError('No flow run found');
        setFlowRunData(null);
      }

      const data = await fetchFlowRun(flowRun.flowRunId, accessToken);
      // Include flowRunId in the data for comparison
      setFlowRunData({ ...data, flowRunId: flowRun.flowRunId });
    } catch (err) {
      setError(`${err.message}`);
      setFlowRunData(null);
    } finally {
      setLoading(false);
    }
  };

  // useEffect to fetch data when visible is true
  useEffect(() => {
    if (visible && flowRun.flowRunId) {
      // Fetch data if we don't have it or if flowRunId has changed
      if (!flowRunData || flowRunData.flowRunId !== flowRun.flowRunId) {
        handleRefresh();
      }
    }
    else {
      setFlowRunData(null);
    }
  }, [visible, flowRun.flowRunId]); // Dependencies

  return (
    <div style={{ fontSize: 8, textAlign: 'right' }}>
      <button onClick={handleRefresh} disabled={loading} style={{ textAlign: 'right' }}>
        {loading ? 'Refreshing...' : 'Server flowrun'}
      </button>

      {/* Display error message */}
      {error && (
        <div style={{ paddingRight: 5, color: 'var(--accent-color-dark)' }}>{error}</div>
      )}

      {/* Display loading state */}
      {loading && <div>Loading...</div>}

      {/* Display data */}
      <div
        style={{
          maxHeight: 400,
          overflow: 'scroll',
          textAlign: 'left',
          fontSize: 10,
        }}
      >
        {flowRunData && (
          <AFJSONViewer src={flowRunData} name="flowRunData" collapsed={2} enableClipboard={true}/>
          
        )}
      </div>
    </div>
  );
};

export default FlowRunDisplay;
