// MemoryCard.js
import React, { useState } from 'react';
import AFJSONViewer from '../../../../../components/AFJSONViewer';
import MemoryEditor from './MemoryEditor';
import '../../styles/memory.css';
import { PiEye } from 'react-icons/pi';
import { isObject } from '../../../../../utils/helpers';
import useFlowAction from '../../../../../hooks/useFlowAction';
import { useSelector } from 'react-redux';
import { selectFlow } from '../../../../../redux/selectors';

const MemoryCard = ({ memory }) => {
  const flow = useSelector(selectFlow);
  const handleFlowAction = useFlowAction(flow?.id);

  const [isEditing, setIsEditing] = useState(false);
  const [currentMemory, setCurrentMemory] = useState(memory);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (updatedMemory) => {
    await handleFlowAction(
      'UPDATE_MEMORY',
      {
        key: updatedMemory.key,
        scope: updatedMemory.scope,
        value: updatedMemory.value,
      },
      `Updating ${updatedMemory.scope} memory: 
      ${updatedMemory.key}...`
    );
    setCurrentMemory(updatedMemory);
    setIsEditing(false);
  };

  const handleDelete = async () => {
    await handleFlowAction(
      'DELETE_MEMORY',
      {
        key: currentMemory.key,
        scope: currentMemory.scope,
      },
      `Deleting ${currentMemory.scope} memory: 
      ${currentMemory.key}...`
    );
    // Optionally, notify parent component to remove this memory card
  };

  const handleClone = async () => {
    await handleFlowAction(
      'CLONE_MEMORY',
      {
        key: currentMemory.key,
        scope: currentMemory.scope,
        value: currentMemory.value,
      },
      `Cloning ${currentMemory.scope} memory: 
      ${currentMemory.key}...`
    );
  };

  const handleRefresh = async () => {
    const result = await handleFlowAction(
      'FETCH_MEMORY',
      {
        key: currentMemory.key,
        scope: currentMemory.scope,
        scopeId: currentMemory.scopeId,
      },
      'Refreshing memory...'
    );
    if (result && result.memory) {
      setCurrentMemory(result.memory);
    }
  };

  if (!currentMemory || !currentMemory.key)
    return <div>I couldn't find relevant memories.</div>;

  return (
    <div className="memory-card">
      <div>
        <strong>{currentMemory.key}</strong>
        <div className="memory-scope">{currentMemory.scope} memory</div>
      </div>
      <div className="memory-value">
       
          <AFJSONViewer src={currentMemory.value} collapsed={0} enableClipboard={true} />
      
      </div>
      <button onClick={handleEdit}>
        <PiEye />
      </button>
      {isEditing && (
        <MemoryEditor
          memory={currentMemory}
          open={isEditing}
          onClose={() => setIsEditing(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          onClone={handleClone}
          onRefresh={handleRefresh}
        />
      )}
    </div>
  );
};

export default MemoryCard;
