// FlowVisualizer.js

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import ReactFlow, {
  Controls,
  Background,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { StepNode, VariableNode } from './CustomNodes';
import AFJSONViewer from '../../../../../components/AFJSONViewer';
import { initializeFlowElements } from '../../../../../utils/graphLayout';
import useFlowConfig from '../../../../../hooks/useFlowConfig';
import FlowViewToolbar from './FlowViewToolbar';
import NodeEditor from './NodeEditor';
import EdgeEditor from './EdgeEditor';
import StepTypes from '../../../../../utils/StepTypes';
import PropTypes from 'prop-types';

const FlowVisualizer = ({ flowConfig, onSaveFlow, onChangeMemory, onShowMemories }) => {
  const { localFlowConfig, updateFlowConfigNode, setLocalFlowConfig } = useFlowConfig(flowConfig);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const reactFlowInstance = useReactFlow();

  const  fitView  = reactFlowInstance.fitView;
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [viewMode, setViewMode] = useState('flow');

  const updateNodeData = useCallback(
    (nodeId, updatedData) => {
      updateFlowConfigNode(nodeId, updatedData);
    },
    [updateFlowConfigNode]
  );

  const addMissingVariables = useCallback(
    (stepData) => {
      const { stepType, operation, inputs } = stepData;
      const inputSchema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;

      if (!inputSchema) return;

      const variableReferences = new Set();

      const extractVariableReferences = (value) => {
        if (typeof value === 'string') {
          const regex = /\$\{([^\}]+)\}/g;
          let match;
          while ((match = regex.exec(value)) !== null) {
            const varExpression = match[1];
            const [variableName] = varExpression.split('.');
            variableReferences.add(variableName);
          }
        } else if (Array.isArray(value)) {
          value.forEach((item) => extractVariableReferences(item));
        } else if (typeof value === 'object' && value !== null) {
          Object.values(value).forEach((val) => extractVariableReferences(val));
        }
      };

      Object.values(inputs).forEach((inputValue) => {
        extractVariableReferences(inputValue);
      });

      setLocalFlowConfig((prevConfig) => {
        const newConfig = { ...prevConfig };
        if (!newConfig.variables) {
          newConfig.variables = {};
        }

        variableReferences.forEach((variableName) => {
          if (!newConfig.variables[variableName]) {
            // Infer the type from the inputSchema
            const field = inputSchema.shape?.[variableName];
            const variableType = field?._def?.typeName || 'string'; // Default to 'string' if type is not found

            // Add the variable to the config
            newConfig.variables[variableName] = {
              name: variableName,
              type: variableType,
              value: '', // Default value can be empty
              isInput: true,
              isOutput: false,
            };
          }
        });

        return newConfig;
      });
    },
    [setLocalFlowConfig]
  );

  const handleSaveNode = useCallback(
    (nodeId, updatedData, nodeType) => {
      updateNodeData(nodeId, updatedData);

      if (nodeType === 'stepNode') {
        addMissingVariables(updatedData);
      }

      setSelectedNode(null);
    },
    [updateNodeData, addMissingVariables]
  );

  const handleEditNode = useCallback((id, data, nodeType) => {
    data.nodeType = nodeType;
    const newSelectedNode = { id, data, type: nodeType };
    setSelectedNode(newSelectedNode);
  }, []);

  const nodeTypes = useMemo(
    () => ({
      stepNode: (props) => <StepNode {...props} onEdit={handleEditNode} />,
      variableNode: (props) => <VariableNode {...props} onEdit={handleEditNode} />,
    }),
    [handleEditNode]
  );

  const updateLayoutFromConfig = useCallback(
    (config = localFlowConfig) => {
      const { nodes: initialNodes, edges: initialEdges } = initializeFlowElements(config);
      setNodes(initialNodes);
      setEdges(initialEdges);
    },
    [setNodes, setEdges]
  );

  useEffect(() => {
    updateLayoutFromConfig(localFlowConfig);
  }, [localFlowConfig, updateLayoutFromConfig]);

  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      fitView();
    }
  }, [nodes, edges, fitView]);

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      const newEdge = {
        ...params,
        label: '',
        data: {
          condition: '',
          description: '',
        },
      };

      // Update the flow config
      if (localFlowConfig.variables[source]) {
        // Source is a variable node connected to a step node
        const variableName = source;
        const step = localFlowConfig.steps[target];
        const { stepType, operation } = step;
        const inputSchema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;

        if (inputSchema) {
          const parameterNames = Object.keys(inputSchema.shape);

          let parameterName = parameterNames.find((param) => param === variableName);

          if (!parameterName) {
            parameterName = parameterNames.find((param) => !step.inputs || !step.inputs[param]);
          }

          if (parameterName) {
            updateFlowConfigNode(target, {
              inputs: {
                ...step.inputs,
                [parameterName]: `\${${variableName}}`,
              },
            });
          } else {
            console.warn(`All parameters for step ${target} are assigned.`);
          }
        } else {
          updateFlowConfigNode(target, {
            inputs: {
              ...step.inputs,
              [variableName]: `\${${variableName}}`,
            },
          });
        }
      } else if (localFlowConfig.variables[target]) {
        // Step node connected to a variable node as output
        updateFlowConfigNode(source, {
          outputs: [...(localFlowConfig.steps[source].outputs || []), target],
        });
      } else {
        // Connecting two step nodes
        updateFlowConfigNode(source, {
          nextSteps: [...(localFlowConfig.steps[source].nextSteps || []), target],
        });
      }

      setEdges((eds) => addEdge(newEdge, eds));
    },
    [localFlowConfig, updateFlowConfigNode]
  );

  const onDeleteNode = useCallback(
    (nodeId) => {
      setLocalFlowConfig((prevConfig) => {
        const newConfig = { ...prevConfig };

        if (newConfig.steps[nodeId]) {
          delete newConfig.steps[nodeId];
        } else if (newConfig.variables[nodeId]) {
          delete newConfig.variables[nodeId];
        }

        // Clean up references to this node in nextSteps and inputs
        Object.keys(newConfig.steps).forEach((stepId) => {
          const step = newConfig.steps[stepId];

          // Remove from nextSteps
          if (step.nextSteps) {
            newConfig.steps[stepId].nextSteps = step.nextSteps.filter((nextStep) => {
              if (typeof nextStep === 'object') {
                return nextStep.stepId !== nodeId;
              } else {
                return nextStep !== nodeId;
              }
            });
          }

          // Remove variable references from inputs
          if (step.inputs) {
            const inputs = { ...step.inputs };
            for (const param in inputs) {
              const value = inputs[param];
              if (typeof value === 'string' && value.includes(`\${${nodeId}}`)) {
                delete inputs[param];
              }
            }
            newConfig.steps[stepId].inputs = inputs;
          }
        });

        return newConfig;
      });
    },
    [setLocalFlowConfig]
  );

  const onDeleteEdge = useCallback(
    (edgeId) => {
      setLocalFlowConfig((prevConfig) => {
        const newConfig = { ...prevConfig };
        const [source, target] = edgeId.split('-');

        // Remove the target from the source's nextSteps
        if (newConfig.steps[source] && newConfig.steps[source].nextSteps) {
          newConfig.steps[source].nextSteps = newConfig.steps[source].nextSteps.filter((nextStep) => {
            if (typeof nextStep === 'object') {
              return nextStep.stepId !== target;
            } else {
              return nextStep !== target;
            }
          });
        }

        // Remove target from the source's outputs if it's a variable connection
        if (newConfig.steps[source] && newConfig.steps[source].outputs) {
          newConfig.steps[source].outputs = newConfig.steps[source].outputs.filter((outputId) => outputId !== target);
        }

        // Remove variable reference from step inputs
        if (newConfig.steps[target] && newConfig.steps[target].inputs) {
          const inputs = { ...newConfig.steps[target].inputs };
          for (const param in inputs) {
            const value = inputs[param];
            if (typeof value === 'string' && value.includes(`\${${source}}`)) {
              delete inputs[param];
            }
          }
          newConfig.steps[target].inputs = inputs;
        }

        return newConfig;
      });
    },
    [setLocalFlowConfig]
  );

  const handleNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => {
        const updatedNodes = applyNodeChanges(changes, nds);

        changes.forEach((change) => {
          if (change.type === 'remove') {
            onDeleteNode(change.id);
          }
        });

        return updatedNodes;
      });
    },
    [onDeleteNode]
  );

  const handleEdgeClick = useCallback(
    (event, edge) => {
      event.stopPropagation();
      setSelectedEdge(edge);
    },
    []
  );

  const handleEdgesChange = useCallback(
    (changes) => {
      setEdges((eds) => {
        const updatedEdges = applyEdgeChanges(changes, eds);

        changes.forEach((change) => {
          if (change.type === 'remove') {
            onDeleteEdge(change.id);
          }
        });

        return updatedEdges;
      });
    },
    [onDeleteEdge]
  );

  const addNewStep = useCallback(() => {
    const { x, y, zoom } = reactFlowInstance.getViewport();

    const newStepId = `Step_${Date.now()}`;
    const newStep = {
      id: newStepId,
      type: 'stepNode',
      data: {
        name: 'New Step',
        stepType: '',
        operation: '',
        inputs: {},
        outputs: [],
        nextSteps: [],
      },
      position: { x: 0, y: 0 },
    };

    setLocalFlowConfig((prevConfig) => {
      const newFlowConfig = { ...prevConfig };

      if (!newFlowConfig.steps) {
        newFlowConfig.steps = {};
      }

      newFlowConfig.steps[newStepId] = { ...newStep.data };
      return newFlowConfig;
    });
  }, [setLocalFlowConfig]);

  const addNewVariable = useCallback(() => {
    const newVarId = `Var_${Date.now()}`;
    const newVariable = {
      id: newVarId,
      type: 'variableNode',
      data: {
        name: 'New Variable',
        type: '',
        value: '',
        isInput: false,
        isOutput: false,
      },
      position: { x: 0, y: 0 },
    };

    setLocalFlowConfig((prevConfig) => {
      const newFlowConfig = { ...prevConfig };

      if (!newFlowConfig.variables) {
        newFlowConfig.variables = {};
      }

      newFlowConfig.variables[newVarId] = { ...newVariable.data };
      return newFlowConfig;
    });
  }, [setLocalFlowConfig]);

  const handleSaveFlow = useCallback(() => {
    if (onSaveFlow) {
      onSaveFlow(localFlowConfig);
    }
  }, [onSaveFlow, localFlowConfig]);

  const handleChangeMemory = useCallback(() => {
    if (onChangeMemory) {
      onChangeMemory();
    }
  }, [onChangeMemory]);

  const handleShowMemories = useCallback(() => {
    if (onShowMemories) {
      onShowMemories();
    }
  }, [onShowMemories]);

  const toggleView = useCallback(() => {
    setViewMode((prevMode) => (prevMode === 'flow' ? 'json' : 'flow'));
  }, []);

  const handleSaveEdge = useCallback(
    (edgeId, updatedData) => {
      setEdges((eds) =>
        eds.map((edge) => {
          if (edge.id === edgeId) {
            return {
              ...edge,
              label: updatedData.condition || '',
              data: {
                ...edge.data,
                condition: updatedData.condition,
                description: updatedData.description,
              },
            };
          }
          return edge;
        })
      );

      const [sourceId, targetId] = edgeId.split('-');
      setLocalFlowConfig((prevConfig) => {
        const newConfig = { ...prevConfig };
        const sourceStep = newConfig.steps[sourceId];

        if (sourceStep) {
          const nextStepIndex = sourceStep.nextSteps.findIndex((step) => {
            if (typeof step === 'object') {
              return step.stepId === targetId;
            } else {
              return step === targetId;
            }
          });

          const updatedNextStep = {
            stepId: targetId,
            condition: updatedData.condition,
            description: updatedData.description,
          };

          if (nextStepIndex !== -1) {
            sourceStep.nextSteps[nextStepIndex] = updatedNextStep;
          } else {
            sourceStep.nextSteps = [...(sourceStep.nextSteps || []), updatedNextStep];
          }
        }

        return newConfig;
      });

      setSelectedEdge(null);
    },
    [setEdges, setLocalFlowConfig]
  );

  const proOptions = { hideAttribution: true };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <FlowViewToolbar
        toggleView={toggleView}
        viewMode={viewMode}
        addNewStep={addNewStep}
        addNewVariable={addNewVariable}
        onSaveFlow={handleSaveFlow}
        onChangeMemory={handleChangeMemory}
        onShowMemories={handleShowMemories}
      />
      <NodeEditor
        selectedNode={selectedNode}
        onSave={handleSaveNode}
        onCancel={() => setSelectedNode(null)}
        steps={localFlowConfig.steps}
      />
      {selectedEdge && (
        <EdgeEditor
          selectedEdge={selectedEdge}
          onSave={handleSaveEdge}
          onCancel={() => setSelectedEdge(null)}
        />
      )}
      {viewMode === 'flow' ? (
        <ReactFlowProvider>
          {nodes.length > 0 && (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={handleNodesChange}
              onEdgesChange={handleEdgesChange}
              onEdgeClick={handleEdgeClick}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              proOptions={proOptions}
            >
              <Controls />
              <Background />
            </ReactFlow>
          )}
        </ReactFlowProvider>
      ) : (
        <AFJSONViewer src={localFlowConfig} enableClipboard={true} collapsed={0} />
      )}
    </div>
  );
};

FlowVisualizer.propTypes = {
  flowConfig: PropTypes.object.isRequired,
  onSaveFlow: PropTypes.func.isRequired,
  onChangeMemory: PropTypes.func,
  onShowMemories: PropTypes.func,
};

export default FlowVisualizer;
