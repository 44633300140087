// components/CustomNodes.js

import React from 'react';
import { Handle, Position } from 'reactflow';
import JSONViewer from 'react-json-view';
import { FiEdit } from 'react-icons/fi';

const StepNode = React.memo(({ id, data, onEdit }) => {
  // Handle edit button click to trigger editing
  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up to node selection
    if (onEdit) {
      onEdit(id, data, 'stepNode'); // Pass id and data directly to onEdit
    }
  };

  // Prepare inputs and outputs for display
  const inputVariables = [];
  if (data?.inputs) {
    const extractVariableReferences = (value) => {
      const variables = [];
      if (typeof value === 'string') {
        const regex = /\$\{([^\}]+)\}/g;
        let match;
        while ((match = regex.exec(value)) !== null) {
          const varExpression = match[1];
          const [variableName] = varExpression.split('.');
          variables.push(variableName);
        }
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          variables.push(...extractVariableReferences(item));
        });
      } else if (typeof value === 'object' && value !== null) {
        Object.values(value).forEach((val) => {
          variables.push(...extractVariableReferences(val));
        });
      }
      return variables;
    };

    Object.values(data.inputs).forEach((inputValue) => {
      inputVariables.push(...extractVariableReferences(inputValue));
    });
  }

  return (
    <div className="step-node">
      <div className="step-node-header">
        <h4>{data?.name || 'Unnamed Step'}</h4>
        <p>
          {data?.stepType || 'Unknown'}.{data?.operation || 'Unknown'}
        </p>
        <button className="edit-button" onClick={handleEditClick}>
          <FiEdit />
        </button>
      </div>
      <div className="step-node-body">
        <p>
          <strong>Inputs:</strong> {inputVariables.length > 0 ? inputVariables.join(', ') : 'None'}
        </p>
        <p>
          <strong>Outputs:</strong> {data?.outputs?.join(', ') || 'None'}
        </p>
      </div>
      <div className="step-node-config">
        <small>
          <strong>Parameters:</strong>
          <JSONViewer
            src={data?.inputs || {}}
            name={false}
            theme="monokai:inverted"
            collapsed={0}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
          />
        </small>
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
});

const VariableNode = React.memo(({ id, data, onEdit }) => {
  // Handle edit button click to trigger editing
  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up to node selection
    if (onEdit) {
      onEdit(id, data, 'variableNode'); // Pass id and data directly to onEdit
    }
  };

  return (
    <div
      className={`variable-node ${data?.isInput ? 'input-variable' : ''} ${
        data?.isOutput ? 'output-variable' : ''
      }`}
    >
      <div className="variable-node-header">
        <h4>{data?.name || 'Unnamed Variable'}</h4>
        <p>
          <strong>ID:</strong> {id}
        </p>
        <button className="edit-button" onClick={handleEditClick}>
          <FiEdit />
        </button>
      </div>
      <div className="variable-node-body">
        <p>
          <strong>Type:</strong> {data?.type || 'Unknown'}
        </p>
        {data?.value && (
          <p>
            <strong>Default Value:</strong> {data.value}
          </p>
        )}
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
});

export { StepNode, VariableNode };
