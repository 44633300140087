const sanitizeUserId = (userId) => {
  return userId.replace(/[^a-zA-Z0-9_-]/g, '');
};

const shortenUserId = (userId) => {
  
  return userId.replace('auth0', '').replace('|', '');
};

const generateChannelId = (flowId, userId) => {
  const sanitizedUserId = sanitizeUserId(shortenUserId(userId));
  return `${flowId}-${sanitizedUserId}`;
};

const sanitizeJsonString = (str) => {
  // Remove control characters and ensure the response is a valid JSON string
  const sanitizedStr = str.replace(/[\u0000-\u001F\u007F-\u009F]/g, '').trim();
  // Remove any extraneous text before or after the JSON
  const jsonStart = sanitizedStr.indexOf('{');
  const jsonEnd = sanitizedStr.lastIndexOf('}') + 1;
  return sanitizedStr.substring(jsonStart, jsonEnd);
};


function formatConfig(config) {
  let formattedString = `Flow Name: ${config.name}\n\nDescription: ${config.description}\n\n`;

  formattedString += `Initial Step: ${config.initialStep}\n\n`;

  formattedString += 'Steps:\n';
  for (const stepKey in config.steps) {
      const step = config.steps[stepKey];
      formattedString += `  - Step ID: ${stepKey}\n`;
      formattedString += `    Name: ${step.name}\n`;
      formattedString += `    Description: ${step.description}\n`;
      formattedString += `    Handler: ${step.handler}\n`;
      formattedString += `    Required Variables: ${step.requiredVariables.join(', ') || 'None'}\n`;
      formattedString += `    Output: ${step.output.join(', ')}\n`;
      if (step.nextSteps) {
          formattedString += `    Next Steps: ${step.nextSteps.join(', ')}\n`;
      }
      formattedString += '\n';
  }

  formattedString += 'Variables:\n';
  for (const varKey in config.variables) {
      const variable = config.variables[varKey];
      formattedString += `  - Variable ID: ${varKey}\n`;
      formattedString += `    Name: ${variable.name}\n`;
      formattedString += `    Type: ${variable.type}\n\n`;
  }

  formattedString += `Input Variables: ${config.inputVariables.join(', ')}\n\n`;
  formattedString += `Output Variables: ${config.outputVariables.join(', ')}\n`;

  return formattedString;
}

function valueToString(value) {
  console.log('valueToString', value);
  if (typeof value === 'string') return value;
  if (value && typeof value === 'object') return JSON.stringify(value, null, 2);
  return String(value);
}

function isHTML(content) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

const isObject = (value) => {
  return value && typeof value === 'object' && !Array.isArray(value);
};

function coerceValue(schema, value) {
  const typeName = schema._def.typeName;

  if (typeName === 'ZodOptional' || typeName === 'ZodNullable') {
    // Unwrap the inner schema and recurse
    return coerceValue(schema._def.innerType, value);
  }

  if (typeName === 'ZodNumber') {
    // Coerce to number
    if (typeof value === 'string' && value.trim() !== '') {
      const num = Number(value);
      if (!isNaN(num)) {
        return num;
      }
    }
    return value; // Let Zod handle the error
  } else if (typeName === 'ZodBoolean') {
    // Coerce to boolean
    if (typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  } else if (typeName === 'ZodArray') {
    // Coerce to array
    if (typeof value === 'string') {
      try {
        // Try parsing JSON array
        const arr = JSON.parse(value);
        if (Array.isArray(arr)) {
          return arr;
        }
      } catch (e) {
        // Split by commas
        return value.split(',').map((v) => v.trim());
      }
    }
    return value;
  } else if (typeName === 'ZodObject') {
    // Coerce to object
    if (typeof value === 'string') {
      try {
        const obj = JSON.parse(value);
        if (typeof obj === 'object' && obj !== null) {
          return obj;
        }
      } catch (e) {
        return value;
      }
    }
    return value;
  } else {
    // Return value as is for other types
    return value;
  }
}





module.exports = { 
  sanitizeUserId,
  generateChannelId,
  shortenUserId,
  sanitizeJsonString, 
  formatConfig, 
  valueToString, 
  isHTML,
  isObject,
  coerceValue
  };
