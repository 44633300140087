// CustomJSONViewer.js
import React from 'react';
import { useSelector } from 'react-redux';
import JSONViewer from 'react-json-view';



const AFJSONViewer = ({ src, 
  collapsed = 1,
  name = false,
  enableClipboard = false, 
  displayDataTypes = false, 
  displayObjectSize = false,
  collapseStringsAfterLength = 400 }) => {
    const darkMode = useSelector(state => state.theme.value === 'dark');  // Check if the current theme is 'dark'
    if(!src) return null;
    if(typeof src !== 'object')
      src = {Content: src};
      // return <div>{src}</div>;
    return (
    <JSONViewer
      src={src}
      collapsed={collapsed}
      theme={darkMode ? 'brewer' : 'rjv-default'}
      name={name}
      enableClipboard={enableClipboard}
      displayDataTypes={displayDataTypes}
      displayObjectSize={displayObjectSize}
      collapseStringsAfterLength={collapseStringsAfterLength}
    />
  );
};

export default AFJSONViewer;
