import React, { useCallback, useEffect, useState } from 'react';
import { selectFlow } from '../../../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { runFlowAndWait } from '../../../../../services/flowService'; // Use the new function
import { useAuth0 } from '@auth0/auth0-react';
import { useStreamClient } from '../../../../../clients/StreamClient';
import { sendMessageToChannel } from '../../../../../services/messageService';

import { PiCheckCircleFill, PiWarningCircleFill, PiXCircleFill } from 'react-icons/pi';

const ValidationStatus = ({ isValidated: initialValidationStatus }) => {
  const dispatch = useDispatch();
  const flow = useSelector(selectFlow);
  const { getAccessTokenSilently, user } = useAuth0();
  const { channel } = useStreamClient();


  // Local state to manage validation status
  const [isValidated, setIsValidated] = useState(initialValidationStatus);

  useEffect(() => {
    setIsValidated(initialValidationStatus);
  }, [initialValidationStatus]);

  let IconComponent;
  let iconColor;
  let tooltipText;

  if (isValidated === true) {
    IconComponent = PiCheckCircleFill;
    iconColor = 'var(--ok-color)';
    tooltipText = 'Valid';
  } else if (isValidated === false) {
    IconComponent = PiXCircleFill;
    iconColor = 'var(--error-color)';
    tooltipText = 'Invalid';
  } else {
    IconComponent = PiWarningCircleFill;
    iconColor = 'var(--warn-color)';
    tooltipText = 'Not Validated';
  }

  const handleValidateFlow = useCallback(async () => {
    
    await sendMessageToChannel(channel, "is this flow valid?");

    const accessToken = await getAccessTokenSilently({
      audience: 'https://www.aflow.ai/api',
      scope: 'writeApi',
    });
    
    try {
      const result = await runFlowAndWait(
        "2f09de75-b448-4696-8251-416a3fcf1ea3",
        {flowId: flow.id}, // Assume inputs are empty for validation, adjust if needed
        user.sub,
        accessToken,
        flow.id, // mainFlowId, set to null or specify if needed
        30000 // Timeout in ms
      );

      // Check the result to determine the validation status
        setIsValidated(result.outputVariables[0].value.result);
      

      // Update the flow run in Redux state if needed
      //dispatch(updateFlowRun({ flowId: flow.id, flowRun: result }));
    } catch (error) {
      console.error('Validation timed out or encountered an error:', error);
      setIsValidated(false); // Optionally set to invalid or other indicator
    }
  }, [dispatch, flow.id, getAccessTokenSilently, user.sub, channel]);

  return (
    <span
      style={{ marginLeft: '8px', marginTop: '8px', cursor: isValidated === null ? 'pointer' : 'pointer' }}
      title={tooltipText}
      onClick={isValidated === null ? handleValidateFlow : handleValidateFlow}
    >
      <IconComponent size={20} color={iconColor} />
    </span>
  );
};

export default ValidationStatus;
