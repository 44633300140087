// src/hooks/useFlowAction.js
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { updateFlowRun } from '../redux/slices/flowSlice';
import { invokeFlow } from '../services/flowService';
import { useStreamClient } from '../clients/StreamClient';

const useFlowAction = (mainFlowId) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const { channel } = useStreamClient();

  const handleFlowAction = useCallback(
    async (flowName, inputs = {}, messageToChannel) => {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.aflow.ai/api',
        scope: 'writeApi',
      });

      const result = await invokeFlow({
        flowName,
        mainFlowId,
        inputs,
        messageToChannel,
        userId: user.sub,
        accessToken,
        channel,
      });

      if (result) {
        dispatch(updateFlowRun({ flowId: mainFlowId, flowRun: result }));
      }
    },
    [dispatch, mainFlowId, getAccessTokenSilently, user.sub, channel]
  );

  return handleFlowAction;
};

export default useFlowAction;
