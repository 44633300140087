// src/components/LogoutButton.js
import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton,Tooltip} from '@mui/material';


function LogoutButton() {
  const { logout, isAuthenticated } = useAuth0(); // Check authentication

  if (!isAuthenticated) return null; // Don't show if not authenticated

  return (
    <Tooltip title="Log out">
    <IconButton size="medium" onClick={() => logout({ returnTo: window.location.origin })}>
    <MdOutlineLogout />
    </IconButton>
  </Tooltip>


  );
}

export default LogoutButton;
