import React from 'react';
import Checkbox from '@mui/material/Checkbox';

const TickBox = ({ checked, onChange, name, readOnly = false }) => {
  return (
    <Checkbox
      sx={{
        color: 'var(--primary-text-dark)',
        '&.Mui-checked': {
          color: 'var(--primary-text-color)',
        },
        '& .MuiSvgIcon-root': {
          //fontSize: 28,
        },
        '&.MuiCheckbox-root .MuiCheckbox-root': {
          padding: 0,
        },
      }}
      name={name}
      checked={checked}
      onChange={readOnly ? undefined : onChange}
      disabled={readOnly} // Disables the checkbox if readOnly is true
    />
  );
};

export default TickBox;
