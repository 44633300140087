// flowMappings.js
export const FlowIds = {
    SAVE_FLOW_CONFIGURATION: '688515c7-040d-462d-a7f0-2ff45b0875c3',
    CHANGE_FLOW_MEMORY: '4cbc2ea9-9750-4889-9244-d12033be9ca5',
    SHOW_FLOW_MEMORIES: '13ba551f-fe3a-4d38-b49b-5c1b00eb9dfb',
    UPDATE_MEMORY: '4cbc2ea9-9750-4889-9244-d12033be9ca5',
  //  DELETE_MEMORY: 'your-delete-memory-flow-id',
  //  CLONE_MEMORY: 'your-clone-memory-flow-id',
    // Add other flow mappings here
  };
  