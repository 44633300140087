// utils/graphLayout.js
import dagre from 'dagre';
import ReactFlow, { Position } from 'reactflow';

const nodeWidth = 200;
const nodeHeight = 80;

export const getLayoutedElements = (nodes, edges) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'LR', nodesep: 150, ranksep: 200 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width || nodeWidth,
      height: node.height || nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

export const initializeFlowElements = (localFlowConfig) => {
  const initialNodes = [];
  const initialEdges = [];
  const { steps, variables, inputVariables, outputVariables } = localFlowConfig;

  if (!steps || Object.keys(steps).length === 0) return { nodes: [], edges: [] };

  Object.keys(steps).forEach((stepId) => {
    const step = steps[stepId];
    initialNodes.push({
      id: stepId,
      type: 'stepNode',
      data: {
        ...step,
      },
      position: { x: 0, y: 0 },
    });

    // Add edges for next steps
    if (step.nextSteps) {
      step.nextSteps.forEach((step) => {
        const nextStepId = typeof step === 'object' ? step.stepId : step;
        initialEdges.push({
          id: `${stepId}-${nextStepId}`,
          source: stepId,
          target: nextStepId,
          type: 'default',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          markerEnd: { type: 'arrowclosed',  width: 20, height: 20 },
          label: step.condition || null,
          data: { condition: step.condition, description: step.description },
        });
      });
    }

    // Add edges for step inputs (Updated)
    if (step.inputs) {
      const variableReferences = new Set();

      const extractVariableReferences = (value) => {
        if (typeof value === 'string') {
          const regex = /\$\{([^\}]+)\}/g;
          let match;
          while ((match = regex.exec(value)) !== null) {
            const varExpression = match[1];
            const [variableName] = varExpression.split('.');
            variableReferences.add(variableName);
          }
        } else if (Array.isArray(value)) {
          value.forEach((item) => extractVariableReferences(item));
        } else if (typeof value === 'object' && value !== null) {
          Object.values(value).forEach((val) => extractVariableReferences(val));
        }
      };

      Object.values(step.inputs).forEach((inputValue) => {
        extractVariableReferences(inputValue);
      });

      variableReferences.forEach((variableName) => {
        initialEdges.push({
          id: `${variableName}-${stepId}`,
          source: variableName,
          target: stepId,
          type:  'default',
          sourcePosition: Position.Left,
          targetPosition: Position.Right,
          markerEnd: { type: 'arrowclosed' },
          animated: true,
        });
      });
    }

    // Add edges for step outputs
    if (step.outputs) {
      step.outputs.forEach((output) => {
        initialEdges.push({
          id: `${stepId}-${output}`,
          source: stepId,
          target: output,
          type: 'default',
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          markerEnd: { type: 'arrowclosed' },
          animated: true,
        });
      });
    }
  });

  Object.keys(variables).forEach((variableName) => {
    const variable = variables[variableName];
    const isInput = inputVariables && inputVariables.includes(variableName);
    const isOutput = outputVariables && outputVariables.includes(variableName);

    initialNodes.push({
      id: variableName,
      type: 'variableNode',
      data: {
        id: variableName,
        ...variable,
        isInput,
        isOutput,
        // Removed updateNodeData from data
      },
      position: { x: 0, y: 0 },
    });
  });

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
  );

  return { nodes: layoutedNodes, edges: layoutedEdges };
};
