import React from 'react';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../../redux/selectors';
import '../../styles/OutputArea.css';

const FlowRunStatusIndicator = () => {
    const flowRun = useSelector(selectFlowRun);
    const flowRunStatus = flowRun?.status || 'undefined';

    const getStatusColor = (status) => {
        switch (status) {
            case 'running':
                return 'white';
            case 'pending':
                return 'var(--warn-color)';
            case 'failed':
                return 'var(--error-color)';
            case 'completed':
                return 'var(--ok-green)';
            default:
                return 'grey';
        }
    };
    const shouldGlow = flowRunStatus !== 'undefined' && flowRunStatus !== 'grey';


    return (
        <div
        className={`flow-run-status-indicator ${shouldGlow ? 'glow' : ''}`}
        style={{ backgroundColor: getStatusColor(flowRunStatus) }}
    ></div>
        );
};

export default FlowRunStatusIndicator;
