// FlowMessage.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import flowIcon from '../../../../../images/aflow-icon-sq-anim.png';
import { shortenUserId, valueToString, isHTML } from '../../../../../utils/helpers';
import { PiArrowBendDownRightFill } from 'react-icons/pi';
import SmartDataTable from './SmartDataTable';
import { updateFlowRun, setCurrentInputRequirement } from '../../../../../redux/slices/flowSlice';
import {
  selectFlowRun,
  selectCurrentInputRequirement,
  selectCurrentInputRequirementType,
  selectFlow,
} from '../../../../../redux/selectors';
import { fetchFlowRunVariables } from '../../../../../services/flowService';
import { setInputValue } from '../../../../../redux/slices/inputSlice';
import '../../styles/chat.css';
import { CheckBox } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import MemoryCard from './MemoryCard';
import MemoryList from './MemoryList';
import MarkDownViewer from '../../../../../components/MarkDownViewer';



const FlowMessage = React.memo((props) => {
  const message = props.message || props;
  const { user } = useSelector((state) => state.user);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const userClass = message.user
    ? shortenUserId(message.user.id) === shortenUserId(user.sub)
      ? 'me'
      : 'others'
    : 'system';

  const isFlowMessage = message.user?.id?.startsWith('flow-');

  const flowRun = useSelector(selectFlowRun);
  const flow = useSelector(selectFlow);
  const currentInputRequirement = useSelector(selectCurrentInputRequirement);
  const currentInputRequirementType = useSelector(selectCurrentInputRequirementType);

  const [variables, setVariables] = useState(
    Array.isArray(message.outputVariables) ? message.outputVariables : []
  );

  const previousCustomTypeRef = useRef();

  // Fetch variables when message changes
  useEffect(() => {
    const fetchVariables = async () => {
      try {
        if (message.outputVariables) {
          setVariables(message.outputVariables);
        } else if (
          (flowRun?.status === 'pending' || flowRun?.status === 'completed') &&
          message.flowRun_id === flowRun?.flowRunId
        ) {
          const accessToken = await getAccessTokenSilently({
            audience: 'https://www.aflow.ai/api',
            scope: 'readApi',
          });
          const fetchedVariables = await fetchFlowRunVariables(
            message.flowRun_id,
            message.outputVariablesNames,
            accessToken
          );
          setVariables(Array.isArray(fetchedVariables) ? fetchedVariables : []);
        }
      } catch (error) {
        console.error('Error fetching flow run variables:', error);
      }
    };

    if (message.outputVariablesNames && message.flowRun_id) {
      fetchVariables();
    }
  }, [
    getAccessTokenSilently,
    flowRun,
    message.outputVariablesNames,
    message.flowRun_id,
    message.outputVariables,
  ]);

  // Update flow run status when message custom type changes
  useEffect(() => {
    const previousCustomType = previousCustomTypeRef.current;

    if (message.flow_id && message.flowRun_id) {
      if (message.customType === 'FlowCompleted' && previousCustomType !== 'FlowCompleted') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'completed' },
          })
        );
      } else if (message.customType === 'InputRequired' && previousCustomType !== 'InputRequired') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'pending' },
          })
        );
        dispatch(
          setCurrentInputRequirement({
            flowId: message.flow_id,
            inputRequirement: message.inputRequired,
          })
        );
      } else if (message.customType === 'FlowFailed' && previousCustomType !== 'FlowFailed') {
        dispatch(
          updateFlowRun({
            flowId: message.flow_id,
            flowRun: { ...flowRun, flowRunId: message.flowRun_id, status: 'failed' },
          })
        );
      }
    }

    previousCustomTypeRef.current = message.customType;
  }, [dispatch, flowRun, message.customType, message.flow_id, message.flowRun_id]);

  const renderFormattedOutput = useCallback(
    (outputArray) => {
      const outputs = Array.isArray(outputArray) ? outputArray : [outputArray];

      return outputs.map((output, index) => {
        if (!output || Object.keys(output).length === 0) return <div key={index}>--</div>;

        const isCurrentFlowPending =
          flowRun?.status === 'pending' && message.flowRun_id === flowRun?.flowRunId;

        const inputKey = Array.isArray(currentInputRequirement)
          ? currentInputRequirement[0]
          : currentInputRequirement;

        const outputType = output?.type;
        const isArrayType = outputType === 'jsonArray' || outputType === 'array';

        const rowSelectionMode = isCurrentFlowPending
          ? isArrayType
            ? 'multiple'
            : 'single'
          : 'none';

        return (
          <div key={index}>
            
            {renderOutputByType(output, rowSelectionMode, inputKey)}
          </div>
        );
      });
    },
    [
      flowRun,
      message.flowRun_id,
      currentInputRequirement,
      currentInputRequirementType,
      dispatch,
    ]
  );

  const renderOutputByType = (output, rowSelectionMode, inputKey) => {
    const outputType = output?.type;
    const entityType = output?.entityType;
    
    if (entityType) {
      switch (entityType) {
        case 'memory':
          if (Array.isArray(output.value)) {
            console.log('output.value', output.value);
            return <MemoryList memories={output.value} />;
          } else {
            return <MemoryCard memory={output.value} />;
          }
        case 'flow':
          break;
        // Add cases for other entity types like 'flow', 'space', etc.
        default:
          return null;
      }
    }

    switch (outputType) {
      case 'json':
      case 'jsonArray':
      case 'array':
        return (
          <div>{(output?.name && <div><strong>{output.name}:</strong><br/></div>)}
          <SmartDataTable
            data={output.value || output}
            rowSelectionMode={rowSelectionMode}
            onSelectionChange={(selected) => {
              if (currentInputRequirementType === 'string' && selected[0]) {
                dispatch(setInputValue({ key: inputKey, value: selected[0].value }));
              } else {
                dispatch(setInputValue({ key: inputKey, value: selected }));
              }
            }}
          /></div>
        );
      case 'boolean':
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckBox checked={output.value} />
            {output.value ? 'Yes' : 'No'}
          </div>
        );
      default:
        if (output?.value) {
          return (
            <div >
              <MarkDownViewer markdownText={valueToString(output.value)}></MarkDownViewer>
            </div>
          );
        }
        return null;
    }
  };

  const renderContent = () => {
    if (message.customType === 'message.date') {
      return <p>{new Date(message.date).toLocaleString()}</p>;
    }

    const outputs = Array.isArray(variables) && variables.length > 0 ? variables : [message.text];
    const len = message.variablesLength || 0;
    const minHeight = len > 10 ? 500 : len> 1 ? len * 74 : 'auto';

    switch (message.customType) {
      case 'FlowCompleted':
        if (!(Array.isArray(variables) && variables.length > 0)) {
          return (
            <div key={message.id} style={{ paddingBottom: 120 }}>
              {<MarkDownViewer markdownText={message.text}></MarkDownViewer>}
            </div>
          );
        }
        return (
          <div key={message.id} style={{ minHeight }}>
            {renderFormattedOutput(outputs)}
          </div>
        );

      case 'InputRequired':
        const requiredVariables = message.inputRequired || [];
        return (
          <div key={message.id}>
            <div>
              {typeof message.inputPrompt === 'object' ? (
                <SmartDataTable data={message.inputPrompt} rowSelectionMode="none" />
              ) : (
                message.inputPrompt || 'Please provide the following:'
              )}
            </div>
            {requiredVariables.map((input, index) => (
              <div key={index} className="message-input">
                <PiArrowBendDownRightFill />
                <div className="input-text">
                  <strong> {message.missingVariables ? message.missingVariables[index]?.name || flow.configuration.variables[input]?.name : input}</strong>
                </div>
              </div>
            ))}
            <div style={{ minHeight, paddingBottom: 20 }}>{renderFormattedOutput(outputs)}</div>
          </div>
        );

      default:
        return (
          <>
            {isHTML(message.text) ? (
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }}
              />
            ) : (
             
                <MarkDownViewer markdownText={message.text}/>
              
            )}
          </>
        );
    }
  };

  return (
    <>
      <div className={`message-${userClass}`} key={message.id}>
        {isFlowMessage ? (
          <img src={flowIcon} alt="Flow Bot" className="avatar" />
        ) : (
          message.user?.image && (
            <img src={message.user.image} alt={message.user.name} className="avatar" />
          )
        )}
        <div className={`message-inner-${userClass}`}>{renderContent()}</div>
      </div>
      <div className="message-notes">
        {message.tokenCount && message.tokenCount > 0
          ? `${message.tokenCount} tokens ✧˖°`
          : null}
      </div>
    </>
  );
});

export default FlowMessage;
