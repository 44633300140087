// src/components/LandingPage.js
import React from 'react';
import Header from './Header';
import ContactForm from './ContactForm';

function Contact({ darkMode, setDarkMode }) {
  return (
    <div>
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <ContactForm />
    </div>
  );
}

export default Contact;
