// VerticalTable.js
import React from 'react';
import AFJSONViewer from '../../../../../components/AFJSONViewer';

const VerticalTable = ({ data }) => {
  return (
    <div >
      <table style={{ height: 'auto', width: '100%' }}>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <th style={{ padding: '8px', textAlign: 'right',
                 backgroundColor: 'var(--secondary-bg-dark)', color: 'var(--primary-text-dark)',
                 fontWeight: 350,
                 borderRadius: 3}}>
                {key.charAt(0).toUpperCase() + key.slice(1)}:
              </th>
              <td style={{ padding: '8px', textAlign: 'left' }}>
                {typeof value === 'object' && value !== null ? (
                  <AFJSONViewer src={value} enableClipboard={true} />
                ) : typeof value === 'boolean' ? (
                  value ? 'Yes' : 'No'
                ) : (
                  value
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VerticalTable;
