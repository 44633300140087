import React, { useState } from 'react';
import '../styles/Hero.css';
import LoginButton from './LoginButton';
import ContactForm from './ContactForm';
import { PiPaperPlaneTiltLight } from 'react-icons/pi';

function Hero() {
  const [showContactForm, setShowContactForm] = useState(false);

  const toggleContactForm = () => {
    setShowContactForm((prev) => !prev);
  };

  return (
    <section className="hero">
      <br />
      <br />
      <div className='hero-button'>
      <h1>Hola, नमस्ते, bonjour, 안녕하세요, olá, Hej!</h1>
     
      <LoginButton /> 
     
      </div>

      {/* Get in Touch Button */}
      <div className='contact-button'>
      <button className="contact-button" onClick={toggleContactForm}>
        <PiPaperPlaneTiltLight/>
        Get in Touch
      </button>
      </div>

      {/* Smooth transition for ContactForm */}
      <div className={`contact-form-container ${showContactForm ? 'visible' : ''}`}>
        <div className="contact-form-backdrop" onClick={toggleContactForm}></div>
        <div className="contact-form-content">
          <ContactForm />
          <button className="close-button" onClick={toggleContactForm}>
            Close
          </button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
