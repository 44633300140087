// MemoryList.js
import React, { useState } from 'react';
import MemoryCard from './MemoryCard';
import { Pagination, Grid2 } from '@mui/material'; // Importing Pagination and Grid from Material-UI
import '../../styles/memory.css';

const MemoryList = ({ memories }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Adjust this number based on your preference

  // Calculate total pages
  const totalPages = Math.ceil(memories.length / itemsPerPage);

  // Calculate the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMemories = memories.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div style={{height:'auto'}}>{currentMemories.length === 0 && <p>I couldn't find relevant memories.</p>}   
      <Grid2 container spacing={2} className="memory-list" >
        {currentMemories.map((memory, index) => (
          <Grid2 item="true" xs={12} sm={6} md={4} key={memory.key || index}>
              <MemoryCard memory={memory} />
          </Grid2>
        ))}
      </Grid2>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
        />
      )}
    </div>
  );
};

export default MemoryList;
