// src/components/LoginButton.js
import React from 'react';
import { MdOutlineLogin } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { IconButton,Tooltip} from '@mui/material';

function LoginButton() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate(); // Get the useNavigate hook for programmatically navigating

  const handleButtonClick = () => {
    if (isAuthenticated) {
      if (window.location.pathname !== '/userarea') {
        navigate('/userarea'); // Redirect to user area if not already there
      }
    } else {
      loginWithRedirect(); // If not authenticated, proceed with login
    }
  };
  //className="btn"

  return (

    <Tooltip title="Log in">
    <IconButton size="medium" onClick={handleButtonClick}>
    <MdOutlineLogin />
    </IconButton>
  </Tooltip>

  );
}

export default LoginButton;
