// DataTable.js
import React, { useState, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import JSONViewer from 'react-json-view';
import Button from '@mui/material/Button';
import TickBox from '../../../../../components/TickBox';
import dataGridStyles from '../../../../../utils/themeUtils';

const DataTable = ({
  data,
  columns,
  rowSelectionMode = 'none',
  onSelectionChange,
  defaultPageSize = 100,
  rowHeight = 36,
  maxRowHeight = 150,
}) => {
  const [expandedCells, setExpandedCells] = useState({});

  /** Helper Functions **/

  // Calculate dynamic width based on data type and content
  const calculateDynamicWidth = useCallback(
    (key) => {
      // Determine data type by inspecting the first row
      if (!data || data.length === 0) return 150; // Default width
      const sampleValue = data[0][key];
      const isUUID = /^[0-9a-fA-F-]{36}$/.test(sampleValue);
      const isNumeric = !isNaN(sampleValue);
      const isObject =
        typeof sampleValue === 'object' && sampleValue !== null;
      const isShortString =
        typeof sampleValue === 'string' &&
        !/\s/.test(sampleValue) &&
        sampleValue.length < 12;

      if (isUUID) return 100;
      if (isNumeric) return 100;
      if (isShortString) return 150;
      if (isObject) return 300;

      const maxLength = data.reduce((acc, row) => {
        const valueLength = row[key] ? row[key].toString().length : 0;
        return Math.max(acc, valueLength);
      }, 0);

      return Math.min(Math.max(maxLength * 10, 150), 500);
    },
    [data]
  );

  // Format cell value based on its type
  const formatCellValue = useCallback(
    (value, rowId, colId) => {
      const cellId = `${rowId}-${colId}`;
      const isExpanded = expandedCells[cellId];

      if (/^[0-9a-fA-F-]{36}$/.test(value)) {
        return (
          <div
            style={{
              color: 'var(--icon-dark)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </div>
        );
      }

      if (typeof value === 'object' && value !== null) {
        return (
          <div style={{ display: 'flex', height: 'auto' }}>
            <JSONViewer
              src={value}
              name={false}
              theme="ocean"
              collapsed={true}
              displayObjectSize={false}
            />
          </div>
        );
      }

      if (Date.parse(value)) {
        return format(new Date(value), 'yyyy-MM-dd HH:mm');
      }

      if (typeof value === 'boolean') {
        return <TickBox checked={value} />;
      }

      return (
        <div>
          {isExpanded ? value : value ? value.slice(0, 100) : ''}
          {value && value.length > 100 && (
            <>
              ...
              <Button
                type="button"
                onClick={() =>
                  setExpandedCells((prev) => ({
                    ...prev,
                    [cellId]: !isExpanded,
                  }))
                }
              >
                {isExpanded ? 'View Less' : 'View More'}
              </Button>
            </>
          )}
        </div>
      );
    },
    [expandedCells]
  );

  // Calculate row height based on content
  const getRowHeight = useCallback(
    ({ id, densityFactor }) => {
      const row = data.find((row) => row.id === id);
      const contentHeight = calculateContentHeight(row, densityFactor);
      return Math.min(contentHeight, maxRowHeight);
    },
    [data, maxRowHeight]
  );

  const calculateContentHeight = (row, densityFactor) => {
    const baseHeight = 36;
    if (!row) return baseHeight;
    const contentLines = Object.values(row).reduce((lines, cellContent) => {
      const cellLength = cellContent ? cellContent.toString().length : 0;
      return lines + Math.ceil(cellLength / 50);
    }, 0);
    return baseHeight * contentLines * densityFactor;
  };

  // Handle row selection changes
  const handleSelectionChange = useCallback(
    (selectionModel) => {
      if (onSelectionChange) {
        const selectedData = data.filter((row) =>
          selectionModel.includes(getRowId(row))
        );
        onSelectionChange(selectedData);
      }
    },
    [data, onSelectionChange]
  );

  const enableSelection = rowSelectionMode !== 'none';

  // Generate columns if not provided
  const generatedColumns = useMemo(() => {
    if (!data || data.length === 0) return [];
    if (columns && columns.length > 0) return columns; // Use provided columns if available
    return Object.keys(data[0] || {}).map((key) => ({
      field: key || 'value',
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      width: calculateDynamicWidth(key),
      minWidth: 150,
      maxWidth: 400,
      colDef: true ,
      renderCell: (params) =>
        formatCellValue(params.value, params.row.id, params.field),
    }));
  }, [columns, data, calculateDynamicWidth, formatCellValue]);
  

  // Generate unique row IDs
  const getRowId = (row) =>
    row.id ||
    row.key ||
    row._id || row.Key || row.ID ||
    row.someOtherUniqueField ||
    `${Math.random().toString(36).substr(2, 9)}`;

  // Ensure all hooks are called before any returns
  // Now check for empty data
  if (!data || data.length === 0) return null;

  // Calculate grid height
  const estimatedHeight =
    data.length * rowHeight +
    36 -
    (data.length <= defaultPageSize ? rowHeight : 0);
  const gridHeight = estimatedHeight < 500 ? 'auto' : 500;

  return (
    <div
      style={{
        width: 'fit-content',
        minWidth: 300,
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: gridHeight,
      }}
    >
      <DataGrid
        rows={data}
        columns={generatedColumns}
        pageSize={defaultPageSize}
        checkboxSelection={enableSelection}
        disableRowSelectionOnClick={true}
        disableMultipleRowSelection={rowSelectionMode !== 'multiple'}
        onRowSelectionModelChange={handleSelectionChange}
        hideFooter={data.length <= defaultPageSize}
        density="compact"
        getRowId={getRowId}
        getRowHeight={() => 'auto'}
        sx={dataGridStyles}
      />
    </div>
  );
};

export default DataTable;
