// src/services/flowService.js
import axios from 'axios';
import { sendMessageToChannel } from '../services/messageService';
import {FlowIds} from '../utils/flowMappings';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

const api = axios.create({
    baseURL: API_BASE_URL
});

export async function fetchFlowDetails(flowId, accessToken) {
    try {
        const response = await api.get(`/flows/${flowId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch flow details:', error);
        throw error;
    }
}

export async function runFlow(flowId, inputs, userId, accessToken) {
    try {
        const response = await api.post('/flows/runflow', { flowId, inputs, userId }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error initializing flow run:', error);
        throw error;
    }
}

export async function runFlowAndWait(flowId, inputs, userId, accessToken, mainFlowId = null, timeout = 30000) {
    try {
        const response = await api.post('/flows/runflowandwait', 
            { flowId, inputs, userId, mainFlowId, timeout },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error running flow and waiting for result:', error);
        throw error;
    }
}
export async function runSubFlow(flowId, inputs, userId, accessToken, mainFlowId) {
    try {
        
        const response = await api.post('/flows/runflow', { flowId, inputs, userId, mainFlowId }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error initializing flow run:', error);
        throw error;
    }
}



export async function executeFlowCommand(flowRunId, stepName, accessToken) {
    try {
        const response = await api.post('/flows/run', { flowRunId, stepName }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error executing flow command:', error);
        throw error;
    }
}

export async function fetchFlows(spaceId, accessToken) {
    try {
      const response = await api.get(`/spaces/${spaceId}/flows`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch flows:', error);
      throw error;
    }
}

export async function resumeFlowRun(flowRunId, inputs, userId, accessToken) {
    try {
        const response = await api.post('/flows/resume', { flowRunId, inputs, userId }, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error resuming flow run:', error);
        throw error;
    }
}


//  function to fetch specific variables of a flow run
export async function fetchFlowRunVariables(flowRunId, variables, accessToken) {
    try {
        const response = await api.post(`flows/flowruns/${flowRunId}/variables  `, 
         { variables },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;  // Return the array of variables
    } catch (error) {
        console.error('Error fetching flow run variables:', error);
        throw error;
    }
}



//  function to fetch specific variables of a flow run
export async function fetchFlowRun(flowRunId, accessToken) {
    try {
        const response = await api.get(`flows/flowruns/${flowRunId}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;  // Return the array of variables
    } catch (error) {
        console.error('Error fetching flow run variables:', error);
        throw error;
    }
}

export async function invokeFlow({
    flowName,     // Name of the flow, to be mapped internally to flowId
    mainFlowId,
    inputs = {},
    messageToChannel,
    userId,
    accessToken,
    channel,
  }) {
    try {
      // Map flowName to flowId
      const flowId = FlowIds[flowName];
      if (!flowId) {
        await sendMessageToChannel(channel, `Flow ID not found for flow name: ${flowName}`)
       return;
      }
  
      // Send message to channel if provided
      if (messageToChannel && channel) {
        await sendMessageToChannel(channel, messageToChannel);
      }
  
      // Run the subflow
      const result = await runSubFlow(
        flowId,
        inputs,
        userId,
        accessToken,
        mainFlowId
      );
  
      return result;
    } catch (error) {
        await sendMessageToChannel(channel, `error: ${error}`)
    }
  }
  
