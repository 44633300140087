// EdgeEditor.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RiQuestionFill } from "react-icons/ri";


const EdgeEditor = ({ selectedEdge, onSave, onCancel }) => {
  const [condition, setCondition] = useState(selectedEdge?.data?.condition || '');
  const [description, setDescription] = useState(selectedEdge?.data?.description || '');

  const handleSave = () => {
    onSave(selectedEdge.id, { condition, description });
  };

  return (
    <div className="form-container">
      <h3>Edit Edge</h3>
      <div className="form-group">
        <label>
          Condition:
          <span className="tooltip">
            <RiQuestionFill />
            <span className="tooltiptext">
              Enter variable ID, operator and value: {'variable != "value"'}.
            </span>
          </span>
        </label>
        <input
          type="text"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          placeholder="e.g., score > 50"
        />
      </div>
      <div className="form-group">
        <label>Description:</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description (optional)"
        />
      </div>
      <div className="toolbar">
        <button onClick={handleSave}>Save</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

EdgeEditor.propTypes = {
  selectedEdge: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EdgeEditor;
