// components/Toolbar.js
import React from 'react';

const FlowViewToolbar = ({ toggleView, viewMode, addNewStep, addNewVariable, onSaveFlow,onChangeMemory,onShowMemories }) => (
  <div className="toolbar">
    <button onClick={onShowMemories}>View memories</button>
    <button onClick={onChangeMemory}>Change memories</button>
    <button onClick={toggleView}>{viewMode === 'flow' ? 'View source' : 'View Flow'}</button>
    <button onClick={addNewStep}>Add Step</button>
    <button onClick={addNewVariable}>Add Variable</button>
    <button onClick={onSaveFlow}>Save Flow</button>
  </div>
);

export default FlowViewToolbar;
