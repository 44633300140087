// OutputArea.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chat, Channel, Window, MessageList } from 'stream-chat-react';
import { useStreamClient } from '../../../clients/StreamClient';
import { useDispatch, useSelector } from 'react-redux';
import FlowMessage from './Components/FlowMessage/FlowMessage';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { selectFlow } from '../../../redux/selectors';
import { clearFlowRun } from '../../../redux/slices/flowSlice';
import FlowInfo from './Components/FlowInfo/FlowInfo';
import './styles/OutputArea.css';
import './styles/chat.css';
import ErrorBoundary from '../../../components/ErrorBoundary';
import TypingIndicatorAF from './Components/FlowMessage/TypingIndicator';

const OutputArea = React.memo(({ isLogVisible, setIsLogVisible, toggleSidebar }) => {
  const flow = useSelector(selectFlow);
  const { chatClient, channel } = useStreamClient();
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const nodeRefs = useRef({});


  const [customTypingText, setCustomTypingText] = useState('');
  const [isTypingActive, setIsTypingActive] = useState(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const EmptyStateIndicator = () => (
    <div className="empty-chat-message">
      <p className="background-message">Start the conversation...</p>
    </div>
  );

  // Typing event listeners
  useEffect(() => {
    if (channel) {
      const handleTypingStart = (event) => {
        if (event.text) {
          setCustomTypingText(event.text); // Set custom text from event
          setIsTypingActive(true);
        }
      };
  
      const handleTypingStop = (event) => {
        if(event.text && event.text == 'stop') {
        setIsTypingActive(false);
        setCustomTypingText('');
        }
      };
  
      channel.on('typing.start', handleTypingStart);
      channel.on('typing.stop', handleTypingStop);
  
      return () => {
        channel.off('typing.start', handleTypingStart);
        channel.off('typing.stop', handleTypingStop);
      };
    }
  }, [channel]);

  // Custom render function for messages
  const customRenderMessages = useCallback(
    (options) => {
      if (options.messages.length === 0) {
        scrollToBottom();
        return <EmptyStateIndicator />;
      }

      const elements = options.messages.map((message) => {
        // Ensure we have a ref for each message
        if (!nodeRefs.current[message.id]) {
          nodeRefs.current[message.id] = React.createRef();
        }
        const nodeRef = nodeRefs.current[message.id];
  
        return (
          <CSSTransition
            key={message.id}
            nodeRef={nodeRef}
            timeout={800}
            classNames="message"
          >
            <li ref={nodeRef}>
              <FlowMessage message={message} />
            </li>
          </CSSTransition>
        );
      });

      return (
        <ErrorBoundary>
          <TransitionGroup component="ul">{elements}</TransitionGroup>
          <TypingIndicatorAF
            isTypingActive={false}
            customText={customTypingText}
          />
          <div ref={messagesEndRef} />
        </ErrorBoundary>
      );
    },
    [isTypingActive, customTypingText]
  );

  const clearMessages = async () => {
    try {
      if (channel) {
        await channel.truncate();
        console.log('Channel truncated');
      }
    } catch (error) {
      console.error('Error truncating channel:', error);
    }
    dispatch(clearFlowRun({ flowId: flow.id }));
  };

  const toggleLogVisibility = () => {
    setIsLogVisible(!isLogVisible);
  };

  useEffect(() => {
    scrollToBottom();
  }, [channel]);

  return (
    <div className="output-area">
      <FlowInfo
        flow={flow}
        clearMessages={clearMessages}
        toggleLogVisibility={toggleLogVisibility}
        toggleSidebar={toggleSidebar}
      />
      <ErrorBoundary>
        <Chat client={chatClient} theme="str-chat__theme-light" TypingIndicator={() => null} >
          <Channel channel={channel} TypingIndicator={() => null} >
            <Window>
              <MessageList
              TypingIndicator={() => null}
                renderMessages={customRenderMessages}
                EmptyStateIndicator={EmptyStateIndicator}
              />
            </Window>
          </Channel>
        </Chat>
      </ErrorBoundary>
    </div>
  );
});

export default OutputArea;
