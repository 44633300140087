// CustomTypingIndicator.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFlowRun } from '../../../../../redux/selectors';
import '../../styles/TypingIndicator.css';

const TypingIndicatorAF = ({ isTypingActive , customText }) => {
 

  // Select flowRun status from Redux
  const flowRunStatus = useSelector((state) => selectFlowRun(state)?.status || 'undefined');


  
  if(flowRunStatus !== 'running') return null;

  return (
    <div className={`typing-indicator-container ${flowRunStatus === 'running' && !isTypingActive ? 'isTyping' : ''}`}>
     <div>
      <div className="speech-bubble">
        <p className="typing-text">{customText}</p>
        <div className="typing-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      </div>
    </div>
  );
};

export default TypingIndicatorAF;
