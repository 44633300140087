import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { PiChatSlash, PiEye, PiEyeSlash } from 'react-icons/pi';
import FlowRunStatusIndicator from './FlowRunStatusIndicator';
import FlowVisualizer from '../FlowVisualizer/FlowVisualizer';
import FlowRunDisplay from './FlowRunDisplay';
import ValidationStatus from './ValidationStatus';
import { selectFlow } from '../../../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setFlowConfig } from '../../../../../redux/slices/flowSlice';

import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { PiHamburgerLight } from 'react-icons/pi';
import useFlowAction from '../../../../../hooks/useFlowAction';
import { ReactFlowProvider } from 'reactflow';

const FlowInfo = ({ clearMessages, toggleSidebar }) => {
  const dispatch = useDispatch();
  const [showVisualizer, setShowVisualizer] = useState(false);
  const [showFlowRunDisplay, setShowFlowRunDisplay] = useState(false);
  const [currentFlowId, setCurrentFlowId] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editableName, setEditableName] = useState('');
  const flow = useSelector(selectFlow);

  const handleFlowAction = useFlowAction(flow?.id);

  // Memoize flow configuration, handling the case where flow might be null
  const flowConfiguration = useMemo(() => flow?.configuration, [flow?.configuration]);

  useEffect(() => {
    if (flow?.id !== currentFlowId) {
      setCurrentFlowId(flow?.id);
      setShowVisualizer(false);
      setShowFlowRunDisplay(false);
      setEditableName(flow?.name || '');
    }
  }, [flow?.id, flow?.name]); // Removed currentFlowId

  const handleSaveFlow = useCallback(
    (flowConfig) => {
      setShowVisualizer(false);
      handleFlowAction(
        'SAVE_FLOW_CONFIGURATION',
        { id: flow.id, data: { configuration: flowConfig } },
        'Save new flow configuration'
      );
      
      dispatch(setFlowConfig(flowConfig));
    },
    [handleFlowAction, flow?.id, flow?.configuration]
  );

  const handleChangeMemory = useCallback(() => {
    setShowVisualizer(false);
    handleFlowAction('CHANGE_FLOW_MEMORY', {scope:'flow'}, 'Change flow memory...');
  }, [handleFlowAction]);

  const handleShowMemories = useCallback(() => {
    setShowVisualizer(false);
    handleFlowAction('SHOW_FLOW_MEMORIES', {}, 'Show flow memories...');
  }, [handleFlowAction]);

  const handleSaveFlowName = useCallback(() => {
    if (editableName.trim() === flow?.name?.trim()) return;

    handleFlowAction(
      'SAVE_FLOW_CONFIGURATION',
      { id: flow.id, data: { name: editableName } },
      `New flow name: ${editableName}`
    );
  }, [editableName, flow?.name, handleFlowAction, flow?.id]);

  const handleNameClick = () => {
    setIsEditingName(true);
  };

  const handleNameChange = (e) => {
    setEditableName(e.target.value);
  };

  const handleNameBlur = () => {
    setIsEditingName(false);
    handleSaveFlowName();
  };

  const toggleVisualizerVisibility = () => {
    setShowFlowRunDisplay(false);
    setShowVisualizer(!showVisualizer);
  };

  const toggleFlowRunDisplayVisibility = () => {
    setShowVisualizer(false);
    setShowFlowRunDisplay(!showFlowRunDisplay);
  };

  if (!flow) return null;

  return (
    <div className="flow-info-container">
      <div className="flow-info">
        <div className="flow-info-header">
          <div className="flow-info-text" style={{ display: 'flex', alignItems: 'center' }}>
            {isEditingName ? (
              <input
                type="text"
                value={editableName}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                className="flow-name-input"
                autoFocus
              />
            ) : (
              <h2 onClick={handleNameClick} className="flow-name">
                {editableName}
              </h2>
            )}
            <ValidationStatus isValidated={flow.validated} />
          </div>

          <button onClick={toggleFlowRunDisplayVisibility} title="Toggle Flow Run Display">
            <FlowRunStatusIndicator />
          </button>

          <button onClick={clearMessages} title="Clear Messages">
            <PiChatSlash />
          </button>

          <button
            className="visualiserToggle"
            onClick={toggleVisualizerVisibility}
            title="Toggle Visualizer"
          >
            {showVisualizer ? <PiEyeSlash /> : <PiEye />}
          </button>
          <button className="headerSideBarToggle" onClick={toggleSidebar}>
            <PiHamburgerLight />
          </button>
        </div>
        <ErrorBoundary>
          <ReactFlowProvider>
            <div className={showVisualizer ? 'flow-info-visualizer' : 'flow-info-visualizer-hidden'}>
              <p>{flow.summary || flow.description}</p>
              {showVisualizer && flowConfiguration && (
                <FlowVisualizer
                  flowConfig={flowConfiguration}
                  onSaveFlow={handleSaveFlow}
                  onChangeMemory={handleChangeMemory}
                  onShowMemories={handleShowMemories}
                />
              )}
            </div>
          </ReactFlowProvider>
        </ErrorBoundary>

        {showFlowRunDisplay && <FlowRunDisplay visible={showFlowRunDisplay} />}
      </div>
    </div>
  );
};

export default FlowInfo;
