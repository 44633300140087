// InputArea.js
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { PiCaretCircleDoubleRightFill, PiAlienLight } from "react-icons/pi";
import { setInputValue, clearInputValue, clearInputValues } from '../../../redux/slices/inputSlice';
import { initializeRun, startRun, resumeRun, updateFlowRun, setCurrentInputRequirement } from '../../../redux/slices/flowSlice';
import { selectFlowRun, selectFlow, selectSelectedSpace, selectInputValues, selectStatus, selectCurrentInputRequirement, selectCurrentInputParameterName, selectFlowRunStatus } from '../../../redux/selectors';
import { useStreamClient } from '../../../clients/StreamClient';
import JSONViewer from 'react-json-view'; 
import './styles/InputArea.css';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CustomPlaceholder from './Components/CustomPlaceholder';
import { Placeholder } from '@tiptap/extension-placeholder';




// Import necessary Material-UI components
import { Typography, IconButton, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function InputArea() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    const submitButtonRef = useRef(null);
    const { channel } = useStreamClient();
    const flow = useSelector(selectFlow);
    const spaceid = useSelector(selectSelectedSpace);
    const flowRun = useSelector(selectFlowRun);
    const inputValues = useSelector(selectInputValues);
    const status = useSelector(selectStatus);
    const currentInputRequirement = useSelector(selectCurrentInputRequirement);
    const currentInputParameterName = useSelector(selectCurrentInputParameterName);
    const flowRunStatus = useSelector(selectFlowRunStatus);


    const [showSelectedData, setShowSelectedData] = useState(true);
    const [messageHistory, setMessageHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [open, setOpen] = useState(false);  // State for collapse functionality

    // Initialize TipTap editor
    const editor = useEditor({
        extensions: [
          StarterKit,
          CustomPlaceholder.configure({
            placeholder: 'Type your message here...', // Initial Placeholder text
          }),
          Placeholder.configure({
            placeholder: '', // Initial Placeholder text
          }),
        ],
        content: '',
        onUpdate: ({ editor }) => {
          autoResizeEditor();
        },
      });
   
    
    // Focus and auto-resize logic remains the same
    useEffect(() => {
        if (editor) {
            if (Object.keys(currentInputRequirement).length > 0) {
                editor.commands.focus();
                autoResizeEditor();
                setShowSelectedData(true);
            } else {
                submitButtonRef.current?.focus();
            }
    
            dispatch(clearInputValues());
        }
    }, [currentInputRequirement, editor, dispatch]);

    const autoResizeEditor = () => {
        const editorContent = document.querySelector('.input-editor');
        if (editorContent) {
            editorContent.style.height = 'auto';
            editorContent.style.height = `${editorContent.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (editor) {
          const placeholder = currentInputParameterName && Object.keys(currentInputParameterName).length > 0
            ? currentInputParameterName
            : 'Type your message here...';
          
          // Update the placeholder text dynamically
          editor.commands.setPlaceholder(placeholder);
        }
      }, [currentInputRequirement, editor]);

    // Set default input if none is defined
    useEffect(() => {
        const isEmptyObject = (obj) => {
            return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
        };

        if (flowRunStatus !== 'pending' && (currentInputRequirement === null || isEmptyObject(currentInputRequirement)) && flow?.configuration?.inputVariables) {
            dispatch(setCurrentInputRequirement({ flowId: flow.id, inputRequirement: [flow.configuration.inputVariables[0]] }));
        }
    }, [flow, flowRun, currentInputRequirement, dispatch]);

    // Handle submit logic
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!flow) return;

        let message = '...';
        let htmlMessage = '...'
        let markdownMessage = '...';
        let inputKey = Array.isArray(currentInputRequirement)
            ? currentInputRequirement[0]
            : currentInputRequirement;
        let inputValue = {};

        if (Object.keys(currentInputRequirement).length === 0) {
            if (editor && editor.getText()) {
                message = editor.getText();
                htmlMessage = editor.getHTML();
                
            }
            inputValue = { [inputKey]: message };
        } else {
            if (editor && editor.getText()) {
                message = editor.getText();
                htmlMessage = editor.getHTML();
                
                inputValue = { [inputKey]: message };
                dispatch(setInputValue({ key: inputKey, value: message }));
            } else if (inputValues[inputKey]) {
                message = inputKey;
                inputValue = { [inputKey]: inputValues[inputKey] };
            } else return;
        }

        setMessageHistory((prev) => [...prev, message]);
        setHistoryIndex(-1);

        try {
            await sendMessageToChannel(channel, message, 'text');
            setShowSelectedData(false);

            const accessToken = await getAccessTokenSilently({
                audience: 'https://www.aflow.ai/api',
                scope: 'readApi',
            });
          
            
            let  updatedInputValues =  {};
            if(Object.keys(inputKey).length > 0 )
                updatedInputValues = { [inputKey]: inputValue[inputKey], spaceId: spaceid.value };
            else
                updatedInputValues = { message: "...", spaceId: spaceid.value };
            
            if (flowRunStatus === 'pending') {
                const resumeResult = await dispatch(resumeRun({ flowRunId: flowRun.flowRunId, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: resumeResult, status: 'running' }));
            } else {
               
                const initializeResult = await dispatch(initializeRun({ flowId: flow.id, inputValues: updatedInputValues, userId: user.sub, accessToken })).unwrap();
                dispatch(updateFlowRun({ flowId: flow.id, flowRun: initializeResult, status: 'running' }));
            }
            dispatch(clearInputValue(inputKey));
            editor?.commands.setContent(''); // Clear editor content
        } catch (error) {
            console.log('Error sending message:', error);
        }
    };

    useEffect(() => {
        if (editor) {
            const editorElement = editor.view.dom;
            editorElement.addEventListener('keydown', handleKeyDown);
    
            // Cleanup function
            return () => {
                editorElement.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [editor]);
    // Handle key down for message history remains the same
    const handleKeyDown = (event) => {

        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault(); // Prevent default behavior of contenteditable for arrow keys
            let newIndex = historyIndex;
    
            if (event.key === 'ArrowUp') {
                newIndex = Math.min(historyIndex + 1, messageHistory.length - 1);
            } else if (event.key === 'ArrowDown') {
                newIndex = Math.max(historyIndex - 1, -1);
            }
             console.log("You clicked me: ", event.key);
            event.preventDefault(); // Prevent default behavior of contenteditable for arrow keys

            setHistoryIndex(newIndex);
    
            if (newIndex >= 0 && newIndex < messageHistory.length) {
                editor?.commands.setContent(messageHistory[messageHistory.length - 1 - newIndex]);
            } else {
                editor?.commands.setContent('');
            }
    
            autoResizeEditor();
        }
    };
    
    
    

    useEffect(() => {
        if (editor) {
            if (historyIndex >= 0 && historyIndex < messageHistory.length) {
                editor.commands.setContent(messageHistory[messageHistory.length - 1 - historyIndex]);
            } else {
                editor.commands.setContent('');
            }
            autoResizeEditor();
        }
    }, [historyIndex, messageHistory, editor]);
    
    
    

    // Extract selected data from inputValues
    const inputKey = Array.isArray(currentInputRequirement)
        ? currentInputRequirement[0]
        : currentInputRequirement;

    let selectedData = null;
    try {
        if (inputValues[inputKey]) {
            selectedData = Array.isArray(inputValues[inputKey]) ? inputValues[inputKey] : [inputValues[inputKey]];
        } else {
            selectedData = null;
        }
        if (selectedData && selectedData.length > 0) submitButtonRef.current?.focus();
    } catch (error) {
        console.error('Error parsing selected data:', error);
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <form onSubmit={handleSubmit} className={Object.keys(currentInputRequirement).length > 0 ? "input-area" : "input-area-alt"}>
            {showSelectedData && selectedData && selectedData.length > 0 && (
                <div className="selected-data">
                    <div onClick={handleToggle} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', transition: 'background-color 0.3s ease' }} className={open ? 'expanded' : ''}>
                        <Typography variant="subtitle1" style={{ fontWeight: open ? 'bold' : 'normal', transition: 'font-weight 0.3s ease' }}>
                            {Array.isArray(selectedData) && selectedData.length === 1 
                            ? `${currentInputRequirement}: 1 item` 
                            : `${currentInputRequirement}: ${selectedData.length} items`}
                        </Typography>
                        <IconButton size="small" style={{ transition: 'transform 0.3s ease', transform: open ? 'rotate(180deg)' : 'rotate(0)', color: 'var(--accent-color-dark)' }}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </div>
                    <Collapse in={open} style={{ transition: 'height 0.3s ease', maxHeight: 400, overflow: 'scroll' }}>
                        <List dense style={{ transition: 'height 0.3s ease', maxHeight: 400 }}>
                            {Array.isArray(selectedData) ? selectedData.map((item, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={typeof item === 'object' ?
                                        <JSONViewer
                                            src={item}
                                            name={false}
                                            theme="ocean"
                                            collapsed={1}
                                            enableClipboard={false}
                                            displayDataTypes={false}
                                            displayObjectSize={false}
                                        />
                                        : item} />
                                </ListItem>
                            )) : (
                                <ListItem>
                                    <ListItemText primary={selectedData} />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>
                </div>
            )}
            <div className='input-field-wrapper'>
            {Object.keys(currentInputRequirement).length > 0 ? (
               
                    <EditorContent editor={editor} className={Object.keys(currentInputRequirement).length > 0 ? "input-field" : "input-field-alt"} />
                
            ) : null}

            <button
                type="submit"
                ref={submitButtonRef}
                className={Object.keys(currentInputRequirement).length > 0 ? "submit-button" : "submit-button-alt"}
                disabled={status === 'loading'}
            >
                {Object.keys(currentInputRequirement).length > 0 ? (
                    <PiCaretCircleDoubleRightFill className="submit-button-text" />
                ) : (
                    <PiAlienLight className="submit-button-text-alt" />
                )}
            </button>
            </div>
        </form>
    );
}

export default InputArea;

async function sendMessageToChannel(channel, message, customType = 'text') {
    try {
        await channel.sendMessage({
            text: message,
            customType: customType,
        });
        
    } catch (error) {
        console.error('Error sending message to channel:', error);
    }
}


