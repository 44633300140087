// EditStepForm.js

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import StepTypes from '../../../../../utils/StepTypes';
import PropTypes from 'prop-types';
import { coerceValue } from '../../../../../utils/helpers';

import { IconButton } from '@mui/material';
import { RiAddLine, RiDeleteBinLine, RiCheckLine, RiCloseLine } from 'react-icons/ri';


const EditStepForm = ({ initialData, onSave, onCancel, steps }) => {
  const [stepData, setStepData] = useState(() => ({
    name: initialData.name || '',
    id: initialData.id || _.kebabCase(initialData.name || ''),
    stepType: initialData.stepType || '',
    operation: initialData.operation || '',
    inputs: initialData.inputs || {},
    inputTypes: {}, // Store input types (value or variable)
    outputs: initialData.outputs || [],
    description: initialData.description || '',
    nextSteps: initialData.nextSteps || [], // Add nextSteps
  }));

  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    const initialInputTypes = {};
    // Initialize inputTypes based on whether the input is a variable reference
    Object.entries(initialData.inputs || {}).forEach(([key, value]) => {
      initialInputTypes[key] =
        typeof value === 'string' && /^\$\{[^\}]+\}$/.test(value)
          ? 'variable'
          : 'value';
    });

    setStepData({
      name: initialData.name || '',
      id: initialData.id || _.kebabCase(initialData.name || ''),
      stepType: initialData.stepType || '',
      operation: initialData.operation || '',
      inputs: initialData.inputs || {},
      inputTypes: initialInputTypes, // Initialize inputTypes
      outputs: initialData.outputs || [],
      description: initialData.description || '',
      nextSteps: initialData.nextSteps || [], // Add nextSteps
    });
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStepData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };
      if (name === 'name') {
        updatedData.id = _.kebabCase(value); /// TODO: change this rename the object, not a property
      }
      return updatedData;
    });
  };

  const handleInputChange = (paramName) => (e) => {
    const { value } = e.target;
    setStepData((prevData) => ({
      ...prevData,
      inputs: {
        ...prevData.inputs,
        [paramName]: value,
      },
    }));
  };

  // Handle input type change (value or variable)
  const handleInputTypeChange = (paramName) => (e) => {
    const { value } = e.target;
    setStepData((prevData) => ({
      ...prevData,
      inputTypes: {
        ...prevData.inputTypes,
        [paramName]: value,
      },
    }));
  };

  // Handle nextSteps change
  const handleNextStepChange = (index, field) => (e) => {
    const { value } = e.target;
    setStepData((prevData) => {
      const nextSteps = [...prevData.nextSteps];
      nextSteps[index] = {
        ...nextSteps[index],
        [field]: value,
      };
      return {
        ...prevData,
        nextSteps,
      };
    });
  };

  // Add new next step
  const addNextStep = () => {
    setStepData((prevData) => ({
      ...prevData,
      nextSteps: [
        ...prevData.nextSteps,
        {
          stepId: '', // Default values
          condition: '',
          description: '',
        },
      ],
    }));
  };

  // Remove next step
  const removeNextStep = (index) => {
    setStepData((prevData) => {
      const nextSteps = [...prevData.nextSteps];
      nextSteps.splice(index, 1);
      return {
        ...prevData,
        nextSteps,
      };
    });
  };

  const validateStepData = async () => {
    const { stepType, operation, inputs, inputTypes } = stepData;
    const schema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;

    if (!schema) {
      setValidationError('Invalid stepType or operation');
      return false;
    }

    const errors = [];
    const parsedInputs = {};
    const fields = schema.shape;

    for (const fieldName of Object.keys(fields)) {
      const fieldSchema = fields[fieldName];
      const inputValue = inputs[fieldName];
      const inputType = inputTypes[fieldName];

      try {
        if (inputType === 'variable') {
          if (
            typeof inputValue !== 'string' ||
            !/^\$\{[^\}]+\}$/.test(inputValue)
          ) {
            throw new Error(`Invalid variable reference format for ${fieldName}`);
          }
          parsedInputs[fieldName] = inputValue;
        } else {
          const coercedValue = coerceValue(fieldSchema, inputValue);
          const result = fieldSchema.safeParse(coercedValue);
          if (!result.success) {
            throw new Error(result.error.message);
          }
          parsedInputs[fieldName] = result.data;
        }
      } catch (e) {
        errors.push(`Error in ${fieldName}: ${e.message}`);
      }
    }

    // Validate nextSteps
    if (stepData.nextSteps) {
      for (const nextStep of stepData.nextSteps) {
        if (!nextStep.stepId) {
          errors.push('Each next step must have a step ID.');
        }
        // Additional validation for condition if needed
      }
    }

    if (errors.length > 0) {
      setValidationError(errors.join(' '));
      return false;
    }

    setValidationError(null);
    return parsedInputs; // Return parsed inputs
  };

  const handleSave = async () => {
    const parsedInputs = await validateStepData();
    if (parsedInputs) {
      const updatedStepData = {
        ...stepData,
        inputs: parsedInputs,
      };
      await onSave(updatedStepData);
    }
  };

  const renderStepConfigInputs = (stepType, operation, inputs, inputTypes) => {
    const schema = StepTypes[stepType]?.OPERATIONS[operation]?.inputSchema;
    if (!schema) return null;

    const fields = schema.shape;
    return Object.keys(fields).map((fieldName) => {
      const field = fields[fieldName];
      const fieldType = field?._def?.typeName || 'string';
      const isRequired = !field.isOptional();
      const value = typeof inputs[fieldName] == 'object' ? JSON.stringify(inputs[fieldName]) : inputs[fieldName] || '';
      const pattern = /\$\{.*?\}/;
      const inputType = pattern.test(value) ? 'variable' : 'value';

      return (
        <div key={fieldName} className="form-group">
          <label className="form-label">
            {fieldName}: {isRequired && <span className="required">*</span>}
          </label>
          <div className="form-row">
            <select
              name={`${fieldName}-type`}
              value={inputType}
              onChange={handleInputTypeChange(fieldName)}
              className="form-input"
            >
              <option value="value">Value</option>
              <option value="variable">Variable</option>
            </select>
            <input
              name={fieldName}
              type={
                inputType === 'value' && fieldType === 'ZodNumber'
                  ? 'number'
                  : 'text'
              }
              value={typeof value == 'object' ? JSON.stringify(value) :  value}
              onChange={handleInputChange(fieldName)}
              className="form-input"
              placeholder={
                inputType === 'variable'
                  ? 'e.g., ${variableName}'
                  : `Enter ${fieldName}`
              }
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="form-container">
      <h3 className="form-header">
        Edit Step:{' '}
        <input
          className="form-input"
          name="name"
          value={stepData.name}
          onChange={handleChange}
          placeholder="Enter step name"
          style={{ fontSize: '1rem', width: '70%', border: 'none' }}
        />
      </h3>

      {/* Step Type and Operation in a single row */}
      <div className="form-row">
        <div className="form-group">
          <label className="form-label">
            Step Type: <span className="required">*</span>
          </label>
          <select
            className="form-input"
            name="stepType"
            value={stepData.stepType}
            onChange={handleChange}
          >
            <option value="">Select Step Type</option>
            {Object.keys(StepTypes).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="form-label">
            Operation: <span className="required">*</span>
          </label>
          <select
            className="form-input"
            name="operation"
            value={stepData.operation}
            onChange={handleChange}
          >
            <option value="">Select Operation</option>
            {Object.keys(StepTypes[stepData.stepType]?.OPERATIONS || {}).map(
              (op) => (
                <option key={op} value={op}>
                  {op}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {stepData.operation && (
        <div className="form-group">
          <label className="form-group-label">Parameters:</label>
          {renderStepConfigInputs(
            stepData.stepType,
            stepData.operation,
            stepData.inputs,
            stepData.inputTypes
          )}
        </div>
      )}

      <div className="form-group">
        <label className="form-group-label">
          Outputs (comma-separated): <span className="required">*</span>
        </label>
        <input
          className="form-input"
          name="outputs"
          value={stepData.outputs.join(', ')}
          onChange={(e) =>
            setStepData({
              ...stepData,
              outputs: e.target.value.split(',').map((s) => s.trim()),
            })
          }
        />
      </div>

     {/* Next Steps Section */}
     <div className="form-group">
        <label className="form-group-label">Next Steps:</label>
        {stepData.nextSteps.map((nextStep, index) => (
          <div key={index} className="next-step-row">
            <div>
            <select
              value={nextStep.stepId}
              onChange={handleNextStepChange(index, 'stepId')}
              className="form-input"
            >
              <option value="">Select Step</option>
              {Object.keys(steps)
                .filter((stepId) => stepId !== stepData.id)
                .map((stepId) => (
                  <option key={stepId} value={stepId}>
                    {steps[stepId].name || stepId}
                  </option>
                ))}
            </select>
            <IconButton onClick={() => removeNextStep(index)}>
              <RiDeleteBinLine />
            </IconButton>
            </div>
            <input
              type="text"
              value={nextStep.condition || ''}
              onChange={handleNextStepChange(index, 'condition')}
              className="form-input"
              placeholder="Condition (optional)"
            />
            <input
              type="text"
              value={nextStep.description || ''}
              onChange={handleNextStepChange(index, 'description')}
              className="form-input"
              placeholder="Description (optional)"
            />
           
          </div>
        ))}
        <IconButton onClick={addNextStep}>
          <RiAddLine />
        </IconButton>
      </div>

      {validationError && <p className="error">{validationError}</p>}

      <div className="toolbar">
      <button onClick={handleSave}>Save</button>
      <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

EditStepForm.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    stepType: PropTypes.string,
    operation: PropTypes.string,
    inputs: PropTypes.object,
    outputs: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    nextSteps: PropTypes.array,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  steps: PropTypes.object.isRequired,
};

export default EditStepForm;
