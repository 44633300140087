// src/theme.js
import { createTheme } from '@mui/material/styles';

export const getMuiTheme = (theme) => {
  const isDarkMode = theme === 'dark';

  return createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: isDarkMode ? '#f0ebde' : '#333333', // Corresponds to --primary-text-dark
      },
      secondary: {
        main: isDarkMode ? '#FF4B4B' : '#FF4B4B', // Corresponds to --accent-color-dark
      },
      background: {
        default: isDarkMode ? '#121212' : '#ffffff', // Corresponds to --primary-bg-dark
        paper: isDarkMode ? '#121212' : '#f8f9fb', // Corresponds to --secondary-bg-dark
      },
      text: {
        primary: isDarkMode ? '#f0ebde' : '#333333', // Corresponds to --primary-text-dark
        secondary: isDarkMode ? '#f8f9fb' : '#645a63', // Corresponds to --secondary-text-dark
      },
    },
    typography: {
      fontFamily: 'inherit',
    },
  });
};
