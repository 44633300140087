import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkDownViewer = ({ markdownText }) => {
  return (
    <div className='markdown-container'>
      <ReactMarkdown>{markdownText}</ReactMarkdown>
    </div>
  );
};

export default MarkDownViewer;
